import React, { useEffect, useState, useRef } from "react";
import CKEditor from "ckeditor4-react";
// import CKEditor from "ckeditor4-react-advanced";
export default function Editor(props) {
  const [dataDefault, setDataDefault] = useState("");
  const [dataDebound, setDataDebound] = useState("");
  const [data, setData] = useState(props?.defaultData);
  const editorRef = useRef(null); // Ref to access CKEditor instance

  useEffect(() => {
    if (window.CKEDITOR) {
      window.CKEDITOR.plugins.addExternal('html5video', '/plugins/html5video/', 'plugin.js');
      window.CKEDITOR.plugins.addExternal('autogrow', '/plugins/autogrow/', 'plugin.js');
      window.CKEDITOR.plugins.addExternal('tableresize', '/plugins/tableresize/', 'plugin.js');
      window.CKEDITOR.plugins.addExternal('editorplaceholder', '/plugins/editorplaceholder/', 'plugin.js');
    }
    if (editorRef?.current) {
      editorRef?.current?.editor?.focus(); // Tự động focus vào CKEditor khi component được mount
    }
  }, []);

  useEffect(() => {
    // insertContentAtCursor(props?.mediaImport[0]?.data);
    for (let i = 0; i < props?.mediaImport?.length; i++) {
      insertContentAtCursor(props?.mediaImport[i]?.data);
    }
  }, [props?.mediaImport]);

  const handleEditorChange = (e) => {
    let data = e.editor.getData().replace(/border="1"/g, 'border="1"')
      .replace(/cellpadding="1"/g, 'cellpadding="5"')
      .replace(/cellspacing="1"/g, 'cellspacing="0"')
      // .replace(/<td>/g, '<td style="width: 50%">')
      .replace(/style="width:500px"/g, 'style="width:100%"')
    // props.setValue(e.editor.getData());
    // console.log('dataedit',data);
    setData(data);
    props.setValue(data);
  };

  // Debounced edit data
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      handleEditorChange(dataDebound);
    }, 1500);
    return () => clearTimeout(timeoutId);
  }, [dataDebound]);

  const insertContentAtCursor = (data) => {
    if (editorRef?.current) {
      const editor = editorRef.current.editor;
      editor?.focus(); // Ensure editor is focused
      const selection = editor?.getSelection();
      const range = selection?.getRanges()[0];
      if (range) {
        const newContent = data;
        editor?.insertHtml(newContent);
      }
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setData(props?.defaultData)
    }, 1500);
    return () => clearTimeout(timeoutId);
  }, [props?.defaultData]);

  const toolbarConfig = [
    [
      "JustifyLeft",
      "JustifyCenter",
      "JustifyRight",
      "Bold",
      "Italic",
      "Underline",
      "NumberedList",
      "BulletedList",
      "Link",
      "Table",
      "Image",
      "Html5video",
      "Autogrow",
      "Tableresize",
      "Editorplaceholder"
    ],
  ];

  const editorConfig = {
    extraPlugins: 'html5video,autogrow,tableresize,editorplaceholder',
    language: 'en', // 'vi' nếu muốn tiếng Việt,
    editorplaceholder: 'Nhập nội dung',
    resize_enabled: true,
    autoGrow_maxHeight: 800,
    autoFocus: true,
    toolbar: toolbarConfig,
    enterMode: 2, // sử dung div thay vì p
    contentsCss: "body {font-size: 14px; height: 170px, font-family:'Roboto', line-height: 1} p {line-height: 1.5;}",
  };

  return (
    <div className="customEdittorContent" style={{zIndex: 10}}>
      <CKEditor
        id="editor"
        ref={editorRef}
        onChange={(e) => setDataDebound(e)}
        config={editorConfig}
        data={data}
        readOnly={props.disable}
      />
      {/* <div
        dangerouslySetInnerHTML={{
          __html: data || "",
        }}
      /> */}
    </div>
  );
}
