import React, { useEffect, useState, useContext } from "react";
import { message } from "antd";
import Service from "./../../../extra/getAPI";
import DanhSachGoi from "./danh-sach-goi";
import TaoGoiSanPham from "./tao-goi-san-pham";
import { AuthContext } from "./../.././../context/AuthProvider";
import moment from "moment";
import "antd/dist/antd.css";
export const GlobalState = React.createContext();

function KhuyenMai() {
  const { setNamePage, indexPage } = useContext(AuthContext);
  const [page, setPage] = useState(1);
  const [loadingIndex, setLoadingIndex] = useState(false);
  const [documentaion, setdocumentaion] = useState({});
  const [product, setProduct] = useState([]);
  const [actionDetail, setActionDetail] = useState("");
  // Data để chuyển qua cập nhật
  const [dataUpdate, setDataUpdate] = useState({});
  // data cho input thời gian chờ
  const [waitingTimes, setWaitingTimes] = useState([
    {
      label: "Tai nạn",
      code: "tai-nan",
      days: 0,
    },
    {
      label: "Bệnh có sẵn",
      code: "benh-co-san",
      days: 0,
    },
    {
      label: "Bệnh đặc biệt",
      code: "benh-dac-biet",
      days: 0,
    },
    {
      label: "Thai sản",
      code: "thai-san",
      days: 0,
    },
    {
      label: "Biến chứng thai sản",
      code: "bien-chung-thai-san",
      days: 0,
    },
    {
      label: "Bệnh thông thường",
      code: "benh-thong-thuong",
      days: 0,
    },
    {
      label: "Răng",
      code: "rang",
      days: 0,
    },
  ]);

  // Quyền lợi nổi bật
  const [arrOutstandingBenefits, setArrOutstandingBenefits] = useState([
    {
      label: "Tử vong, thương tật vĩnh viễn do tai nạn",
      code: "tu_vong_thuong_tat_tai_nan",
      value: 0,
    },
    {
      label: "Tử vong, thương tật vĩnh viễn do bệnh tật, thai sản",
      code: "tu_vong_thuong_tat_benh_thai_san",
      value: 0,
    },
    {
      label: "Nội trú/ năm",
      code: "noi_tru_tren_nam",
      value: 0,
    },
    {
      label: "Viện phí/ ngày",
      code: "vien_phi_tren_ngay",
      value: 0,
    },
    {
      label: "Ngoại trú/ năm",
      code: "ngoai_tru_tren_nam",
      value: 0,
    },
    {
      label: "Giới hạn/ lần khám",
      code: "gioi_han_tren_lan_kham",
      value: 0,
    },
    {
      label: "Khác",
      code: "khac",
      value: "",
    },
  ]);

  const [question, setQuestion] = useState([
    { id: 1, content: "", required: false },
  ]);

  const [additionalTerms, setAdditionalTerms] = useState([
    {
      id: 1,
      checked: false,
      content:
        "Bảo hiểm cho stent động mạch vành, van tim, cathenter trong phẫu thuật tim, lưới trong phẫu thuật thoát vị bẹn, dao longo đối với sự kiện bảo hiểm thuộc phạm vi bảo hiểm",
    },
    {
      id: 2,
      checked: false,
      content:
        "Bảo hiểm cho Natri clorid, Sterima, Humer, Marinmer, Xisat trong quyền lợi điều trị ngoại trú đối với sự kiện bảo hiểm thuộc phạm vi bảo hiểm",
    },
    {
      id: 3,
      checked: false,
      content:
        "Bảo hiểm cho điều trị nấm âm đạo, nấm phụ khoa gây ra bởi Chlamydia, nấm Candida hoặc tạp khuẩn",
    },
    {
      id: 4,
      checked: false,
      content:
        "Vitamin và thuốc bổ: chi trả theo chi phí y tế thực tế theo chỉ định của bác sỹ để hỗ trợ điều trị bệnh và số ngày sử dụng của vitamin và thuốc bổ không quá số ngày sử dụng của thuốc chính. Tối đa không quá VND 500,000/ người/ năm",
    },
    {
      id: 5,
      checked: false,
      content: "Mở rộng bảo hiểm cho Covid-19",
    },
  ]);

  const [relationship, setRelationship] = useState([
    {
      checked: 1,
      name: "Con hợp pháp",
      code:"con_hop_phap"
    },
    {
      checked: true,
      name: "Anh chị em ruột",
      code:"anh_chi_em_ruot"
    },
    {
      checked: true,
      name: "Vợ/chồng",
      code:"vo_chong"
    },
    {
      checked: true,
      name:"Người khác có quan hệ nuôi dưỡng, cấp dưỡng",
      code:"nguoi_khac_co_moi_quan_he_nuoi_duong_cap_duong"
    },
    {
      checked: true,
      name: "Người sử dụng lao động",
      code:"nguoi_su_dung_lao_dong"
    },
  ]);

  const [mainBenefits, setMainBenefits] = useState([
    {
      kind: "primary",
      status: 1,
      details: [
        {
          gender: null,
        },
      ],
    },
  ]);

  useEffect(() => {
    setNamePage("Sản phẩm");
    getProducts();
  }, []);

  useEffect(() => {
    setPage(1);
  }, [indexPage === 1]);

  // Lấy danh sách sản phẩm
  const getProducts = () => {
    Service.apicall(
      "GET",
      `/api/v1/products-hub/health/portal/product-by-provider`
    )
      .then((res) => {
        if (res.data.statusCode === 200) {
          setProduct(res.data.result.productByProvider);
        }
      })
      .catch((err) => {});
  };

  // Them sua xoa thong tin phan cau hoi tham dinh
  const manipulateArrayQuestion = (action, index, newValue) => {
    const updatedItems = [...question];
    switch (action) {
      case "add":
        updatedItems.push(newValue);
        break;
      case "edit":
        updatedItems[index] = newValue;
        break;
      case "delete":
        updatedItems.splice(index, 1);
        break;
      default:
        break;
    }
    setQuestion(updatedItems);
  };

  // Them sua xoa dieu khoan bo sung
  const manipulateArrayAdditionalTerms = (action, index, newValue) => {
    const updatedItems = [...additionalTerms];
    switch (action) {
      case "add":
        updatedItems.push(newValue);
        break;
      case "edit":
        updatedItems[index] = newValue;
        break;
      case "delete":
        updatedItems.splice(index, 1);
        break;
      default:
        break;
    }
    setAdditionalTerms(updatedItems);
  };

    // Them sua xoa moi quan he
    const manipulateArrayRelationship = (action, index, newValue) => {
      const updatedItems = [...relationship];
      switch (action) {
        case "add":
          updatedItems.push(newValue);
          break;
        case "edit":
          updatedItems[index] = newValue;
          break;
        case "delete":
          updatedItems.splice(index, 1);
          break;
        default:
          break;
      }
      setRelationship(updatedItems);
    };

  // Them sua xoa tao quyen loi chinh
  const handleDataChangeMainBenefits = (
    type,
    index,
    detailIndex,
    updatedData
  ) => {
    const newBenefits = [...mainBenefits];
    switch (type) {
      case "addBenefit":
        newBenefits.push(updatedData);
        break;
      case "updateBenefit":
        newBenefits[index] = updatedData;
        break;
      case "removeBenefit":
        newBenefits.splice(index, 1);
        break;
      case "addDetail":
        newBenefits[index].details.push(updatedData);
        break;
      case "updateDetail":
        newBenefits[index].details[detailIndex] = updatedData;
        if (
          newBenefits[index]?.category_fees !== "phan_tram"
        ) {
          ValiDateAgeBenefits(
            newBenefits[index].details,
            index,
            detailIndex,
            updatedData
          );
        }
        break;
      case "removeDetail":
        newBenefits[index].details.splice(detailIndex, 1);
        break;
      default:
        break;
    }
    setMainBenefits(newBenefits);
  };

  // Kiểm tra không được trùng khoản độ tuổi
  const ValiDateAgeBenefits = (detail, index, detailIndex, updatedData) => {
    const newUpadte = { ...updatedData };
    // Check theo tuoi
    if (
      !newUpadte?.gender &&
      newUpadte.from_age !== null &&
      newUpadte.from_age !== undefined &&
      newUpadte.to_age !== null &&
      newUpadte.to_age !== undefined
    ) {
      const filterGender = detail.filter((item, i) => i !== detailIndex);
      CheckAgeNoGender(filterGender, newUpadte, index, detailIndex);
      return;
    }
    // Check theo tuổi và giới tính
    if (
      newUpadte?.gender &&
      newUpadte.from_age !== null &&
      newUpadte.from_age !== undefined &&
      newUpadte.to_age !== null &&
      newUpadte.to_age !== undefined
    ) {
      const filterGender = detail.filter(
        (item, i) => item?.gender === newUpadte?.gender && i !== detailIndex
      );
      CheckAge(filterGender, newUpadte, index, detailIndex);
    }
  };

  const CheckAge = (arrbenefits, dataupdate, index, detailIndex) => {
    if (
      dataupdate.from_age > dataupdate.to_age &&
      dataupdate.from_age > 0 &&
      dataupdate.to_age > 0
    ) {
      message.error("Phân loại tuổi chưa hợp lệ!");
      handleDataChangeMainBenefits("updateDetail", index, detailIndex, {
        gender: dataupdate.gender,
        from_age: null,
        to_age: null,
        fees: dataupdate.fees,
      });
      return;
    }
    arrbenefits.map((item) => {
      if (
        (dataupdate.to_age < item.from_age &&
          dataupdate.from_age < item.to_age) ||
        (dataupdate.to_age > item.from_age && dataupdate.from_age > item.to_age)
      ) {
        //console.log('Khoản tuổi đúng')
      } else {
        message.error("Phân loại tuổi chưa hợp lệ!");
        handleDataChangeMainBenefits("updateDetail", index, detailIndex, {
          gender: dataupdate.gender,
          from_age: null,
          to_age: null,
          fees: dataupdate.fees,
        });
      }
    });
  };

  const CheckAgeNoGender = (arrbenefits, dataupdate, index, detailIndex) => {
    if (
      dataupdate.from_age > dataupdate.to_age &&
      dataupdate.from_age > 0 &&
      dataupdate.to_age > 0
    ) {
      message.error("Phân loại tuổi chưa hợp lệ!");
      handleDataChangeMainBenefits("updateDetail", index, detailIndex, {
        from_age: null,
        to_age: null,
        fees: dataupdate.fees,
      });
      return;
    }
    arrbenefits.map((item) => {
      if (
        (dataupdate.to_age < item.from_age &&
          dataupdate.from_age < item.to_age) ||
        (dataupdate.to_age > item.from_age && dataupdate.from_age > item.to_age)
      ) {
        //console.log('Khoản tuổi đúng')
      } else {
        message.error("Phân loại tuổi chưa hợp lệ!");
        handleDataChangeMainBenefits("updateDetail", index, detailIndex, {
          from_age: null,
          to_age: null,
          fees: dataupdate.fees,
        });
      }
    });
  };

  // Them va xoa input quyền lợi nổi bật
  const handleModifyBenefits = (action, index, newValue) => {
    const updatedItems = [...arrOutstandingBenefits];
    switch (action) {
      case "add":
        updatedItems.push(newValue);
        break;
      case "edit":
        updatedItems[index] = newValue;
        break;
      case "delete":
        updatedItems.splice(index, 1);
        break;
      default:
        break;
    }
    setArrOutstandingBenefits(updatedItems);
  };

  // Them va xoa input thoi gian cho
  const handleModifyWaitingTime = (action, index, newValue) => {
    const updatedItems = [...waitingTimes];
    switch (action) {
      case "add":
        updatedItems.push(newValue);
        break;
      case "edit":
        updatedItems[index] = newValue;
        break;
      case "delete":
        updatedItems.splice(index, 1);
        break;
      default:
        break;
    }
    setWaitingTimes(updatedItems);
  };

  const PageView = () => {
    switch (page) {
      case 1:
        return <DanhSachGoi />;
      case 2:
        return <TaoGoiSanPham />;
    }
  };

  const Back = (page) => {
    setPage(page);
    setdocumentaion({});
    setQuestion([{ id: 1, content: "", required: false }]);
    setAdditionalTerms([
      {
        id: 1,
        checked: false,
        content:
          "Bảo hiểm cho stent động mạch vành, van tim, cathenter trong phẫu thuật tim, lưới trong phẫu thuật thoát vị bẹn, dao longo đối với sự kiện bảo hiểm thuộc phạm vi bảo hiểm",
      },
      {
        id: 2,
        checked: false,
        content:
          "Bảo hiểm cho Natri clorid, Sterima, Humer, Marinmer, Xisat trong quyền lợi điều trị ngoại trú đối với sự kiện bảo hiểm thuộc phạm vi bảo hiểm",
      },
      {
        id: 3,
        checked: false,
        content:
          "Bảo hiểm cho điều trị nấm âm đạo, nấm phụ khoa gây ra bởi Chlamydia, nấm Candida hoặc tạp khuẩn",
      },
      {
        id: 4,
        checked: false,
        content:
          "Vitamin và thuốc bổ: chi trả theo chi phí y tế thực tế theo chỉ định của bác sỹ để hỗ trợ điều trị bệnh và số ngày sử dụng của vitamin và thuốc bổ không quá số ngày sử dụng của thuốc chính. Tối đa không quá VND 500,000/ người/ năm",
      },
      {
        id: 5,
        checked: false,
        content: "Mở rộng bảo hiểm cho Covid-19",
      },
    ]);
    setMainBenefits([
      {
        kind: "primary",
        status: 1,
        details: [
          {
            gender: null,
          },
        ],
      },
    ]);
    setDataUpdate({});
    setWaitingTimes([
      {
        label: "Tai nạn",
        code: "tai-nan",
        days: 0,
      },
      {
        label: "Bệnh có sẵn",
        code: "benh-co-san",
        days: 0,
      },
      {
        label: "Bệnh đặc biệt",
        code: "benh-dac-biet",
        days: 0,
      },
      {
        label: "Thai sản",
        code: "thai-san",
        days: 0,
      },
      {
        label: "Biến chứng thai sản",
        code: "bien-chung-thai-san",
        days: 0,
      },
      {
        label: "Bệnh thông thường",
        code: "benh-thong-thuong",
        days: 0,
      },
      {
        label: "Răng",
        code: "rang",
        days: 0,
      },
    ]);
    setArrOutstandingBenefits([
      {
        label: "Tử vong, thương tật vĩnh viễn do tai nạn",
        code: "tu_vong_thuong_tat_tai_nan",
        value: 0,
      },
      {
        label: "Tử vong, thương tật vĩnh viễn do bệnh tật, thai sản",
        code: "tu_vong_thuong_tat_benh_thai_san",
        value: 0,
      },
      {
        label: "Nội trú/ năm",
        code: "noi_tru_tren_nam",
        value: 0,
      },
      {
        label: "Viện phí/ ngày",
        code: "vien_phi_tren_ngay",
        value: 0,
      },
      {
        label: "Ngoại trú/ năm",
        code: "ngoai_tru_tren_nam",
        value: 0,
      },
      {
        label: "Giới hạn/ lần khám",
        code: "gioi_han_tren_lan_kham",
        value: 0,
      },
      {
        label: "Khác",
        code: "khac",
        value: "",
      },
    ]);
    setRelationship([
      {
        checked: true,
        name: "Con hợp pháp",
        code:"con_hop_phap"
      },
      {
        checked: true,
        name: "Anh chị em ruột",
        code:"anh_chi_em_ruot"
      },
      {
        checked: true,
        name: "Vợ/chồng",
        code:"vo_chong"
      },
      {
        checked: true,
        name:"Người khác có quan hệ nuôi dưỡng, cấp dưỡng",
        code:"nguoi_khac_co_moi_quan_he_nuoi_duong_cap_duong"
      },
      {
        checked: true,
        name: "Người sử dụng lao động",
        code:"nguoi_su_dung_lao_dong"
      },
    ]);
  };

  // Úp hình và file
  const uploadG2 = (file, name, productName, childProduct) => {
    if (loadingIndex) {
      message.warning(
        "Tập tin trước đang được xử lý. Vui lòng chờ trong giây lát."
      );
      return;
    }
    setLoadingIndex(true);
    var form = new FormData();
    form.append("storage", "s3");
    form.append("file", file);
    form.append(
      "path",
      `/products/assets/${productName}/${childProduct}/${moment().format(
        "YYYY-MM-DD"
      )}`
    );
    Service.apicall_upload_G2("POST", `/upload`, form)
      .then((res) => {
        setLoadingIndex(false);
        let data = res?.data;
        if (data?.complete) {
          // setdocumentaion([...documentaion, { name: file.name, link: data.link }]);
          message.success("Cập nhật tài liệu thành công");
          setdocumentaion({ ...documentaion, [name]: [data.link] });
        }
      })
      .catch((err) => {
        setLoadingIndex(false);
      });
  };

  return (
    <GlobalState.Provider
      value={{
        setPage,
        page,
        manipulateArrayQuestion,
        question,
        additionalTerms,
        manipulateArrayAdditionalTerms,
        mainBenefits,
        handleDataChangeMainBenefits,
        mainBenefits,
        waitingTimes,
        setWaitingTimes,
        handleModifyWaitingTime,
        uploadG2,
        documentaion,
        setdocumentaion,
        Back,
        product,
        dataUpdate,
        setDataUpdate,
        setQuestion,
        setMainBenefits,
        setAdditionalTerms,
        getProducts,
        loadingIndex,
        setLoadingIndex,
        handleModifyBenefits,
        arrOutstandingBenefits,
        setArrOutstandingBenefits,
        actionDetail,
        setActionDetail,
        relationship, 
        setRelationship,
        manipulateArrayRelationship
      }}
    >
      <div>{PageView()}</div>
    </GlobalState.Provider>
  );
}

export default KhuyenMai;
