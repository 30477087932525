import React, { useEffect, useState, useContext } from "react";
import { Button, Modal, Form, Input, message } from "antd";
import Helper from "./../../../../extra/helper";
import { GlobalState } from "./../../../../pages/san-pham/suc-khoe-vang/index";

function MDBenefits(props) {
  const { handleModifyBenefits } = useContext(GlobalState);
  const [form] = Form.useForm();
  const formRef = React.createRef();

  useEffect(() => {
    const data = props?.data;
    if (data) {
      form.setFieldsValue({ name: data.label });
    }
  }, []);

  const onFinish = (val) => {
    form
      .validateFields()
      .then(async (values) => {
        if (props?.data) {
          handleModifyBenefits("edit", props?.data?.idEdit, {
            label: values.name,
            code: Helper.generateCodeInput(values.name),
            value: props?.data?.value,
          });
        }
        props.handleCancel();
      })
      .catch((err) => {
        console.error("err:", err);
      });
  };

  return (
    <>
      <Modal
        footer={false}
        className="modal-custom"
        visible={props?.open}
        centered={true}
        closable={false}
        onCancel={props?.handleCancel}
        style={{ borderRadius: 10, maxWidth: "350px" }}
      >
        <Form
          ref={formRef}
          scrollToFirstError
          form={form}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            className="username iputTextArea"
            style={{ marginBottom: 10 }}
            label={<span style={{ fontWeight: 400 }}>Nhập tên</span>}
            name="name"
            rules={[
              {
                required: true,
                message: "Vui lòng nhập đầy đủ thông tin.",
              },
            ]}
          >
            {/* <Input placeholder="Nhập tên" /> */}
            <Input.TextArea
              allowClear
              style={{
                resize: "none",
                padding: "10px 10px",
                borderRadius: 7,
              }}
              rows={1}
              autoSize
              placeholder="Nhập tên"
            />
          </Form.Item>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 15,
            }}
          >
            <Button
              type="primary"
              htmlType="submit"
              style={{
                background: "#3961A0",
                fontSize: 14,
                border: "1px solid #3961A0",
                fontWeight: 600,
                // width: 80,
                borderRadius: 5,
              }}
            >
              {props?.data ? "Cập nhật" : "Thêm"}
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
}

export default MDBenefits;
