import React, { useEffect, useState, useContext } from "react";
import { Button, Modal, Form, Input, message } from "antd";
import { AuthContext } from "./../../../context/AuthProvider";
import { Content } from "antd/lib/layout/layout";

// Kiểm duyệt sản phẩm
export default function CancelOrder(props) {
  const { profile } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const formRef = React.createRef();

  const onFinish = (val) => {
    form
      .validateFields()
      .then(async (values) => {
        props.reject(values.content);
      })
      .catch((err) => {
        console.error("err:", err);
      });
  };

  return (
    <>
      <Modal
        footer={false}
        className="modal-custom"
        visible={props?.open}
        centered={true}
        closable={false}
        // onCancel={props?.handleCancel}
        style={{ borderRadius: 10, maxWidth: "600px" }}
      >
        <Form
          layout={"vertical"}
          ref={formRef}
          scrollToFirstError
          form={form}
          onFinish={onFinish}
        >
          <>
            <div>
              <div>
                <b>{props.status === 10?'Lý do huỷ đơn':'Lý do từ chối đơn'}</b>
              </div>
              <Form.Item
                // className="username"
                style={{ margin: "10px 0px", width: "100%" }}
                name={"content"}
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập thông tin.",
                  },
                ]}
              >
                <TextArea
                  autoSize={{
                    minRows: 6
                  }}
                  placeholder="Nhập nội dung"
                />
              </Form.Item>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 15,
              }}
            >
              <>
                <Button
                  onClick={() => props?.handleCancel()}
                  type="primary"
                  style={{
                    background: "#fff",
                    fontSize: 14,
                    border: "1px solid #0B7CE4",
                    fontWeight: 600,
                    width: 80,
                    color: "#0B7CE4",
                    marginRight: 10,
                  }}
                >
                  Bỏ qua
                </Button>
                <Button
                  loading={props?.loading}
                  htmlType="submit"
                  type="primary"
                  style={{
                    background: "#0B7CE4",
                    fontSize: 14,
                    border: "1px solid #0B7CE4",
                    fontWeight: 600,
                    minWidth: 80,
                  }}
                >
                  Cập nhật
                </Button>
              </>
            </div>
          </>
        </Form>
      </Modal>
    </>
  );
}
