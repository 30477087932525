import React, { useEffect, useState, useContext } from "react";
import { Form, Select, Upload, message } from "antd";
import Helper from "./../../extra/helper";
import {
  LoadingOutlined
} from '@ant-design/icons';
import "antd/dist/antd.css";
import Service from "./../../extra/getAPI";
import Iupload from "./../../assets/images/salesManager/iUpload.png";
import { AuthContext } from "./../../context/AuthProvider";
import moment from "moment";

function UploadFile(props) {
  const { profile, product} = useContext(AuthContext);
  const { height } = props;
  const { Option } = Select;
  const { Dragger } = Upload;
  const [form] = Form.useForm();
  const formRef = React.createRef();
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [indexUpload, setIndexUpload] = useState(0);
  const maxTotalSize = 10 * 1024 * 1024; // Dung lượng 10M


  useEffect(() => {

  }, []);

  const onFinish = (val) => {
    form
      .validateFields()
      .then(async (values) => {
        const totalSize = val.reduce((acc, file) => acc + file.size, 0);
        if (totalSize > maxTotalSize) {
          message.error("Dung lượng file quá lớn. Vui lòng kiểm tra lại!");
          return;
        }
        const newFileArray = val.map(item => ({
          size: item.size,
          url: item.originFileObj, // Đây là giả sử url lấy từ originFileObj
          name: item.name,
          mime_type: item.type,
          type: Helper._renderTypeFile(item.type),
          product_id: +values.product_id,
          product_title: product.find((item) => item.id === +values.product_id)?.title,
          // pbp_id: productsByProvider.find((item) => item.id === +values.product_id)?.id,
          // pbp_title: productsByProvider.find((item) => item.id === +values.product_id)?.product_title
        }));
        uploadG2(newFileArray, indexUpload)
        // console.log('newArray', newFileArray);
        // console.log(values);
      })
      .catch((err) => {
        console.error("err:", err);
      });
  };

  // Úp hình và file
  const uploadG2 = (newFileArray, indexUpload) => {
    const uidNhaBaoHiem = profile?.uid;
    const maLoaiBoaHiem = newFileArray[indexUpload].product_id;
    const type = newFileArray[indexUpload].type;
    const file = newFileArray[indexUpload].url;
    if (loading) {
      message.warning(
        "Tập tin trước đang được xử lý. Vui lòng chờ trong giây lát."
      );
      return;
    }
    setLoading(true);
    var form = new FormData();
    form.append("storage", "s3");
    form.append("file", file);
    form.append(
      "path",
      `/products-hub/${maLoaiBoaHiem}/${uidNhaBaoHiem}/media-gallery/${type}/${moment().format(
        "YYYY-MM-DD"
      )}`
    );
    Service.apicall_upload_G2("POST", `/upload`, form)
      .then((res) => {
        let data = res?.data;
        if (data?.complete) {
          mediaGallery(newFileArray, indexUpload, data.link);
          // message.success("Cập nhật tài liệu thành công");
          // setdocumentaion({ ...documentaion, [name]: [data.link] });
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const mediaGallery = (newFileArray, indexUpload, link) => {
    const data = { ...newFileArray[indexUpload], url: link };
    // console.log('Arr đã có link từ server', data);
    // console.log('Arr cũ', newFileArray);
    Service.apicall(
      "POST",
      `/api/v1/products-hub/media-gallery`, data
    )
      .then((res) => {
        if (res.data.statusCode === 200) {
          if (indexUpload === (newFileArray.length - 1)) {
            message.success('Tải file hoàn tất.');
            setLoading(false);
            return
          }
          uploadG2(newFileArray, indexUpload + 1);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  return (
    <>
      <Form
        ref={formRef}
        scrollToFirstError
        form={form}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          name="product_id"
          rules={[
            {
              required: true,
              message: "Vui lòng chọn thông tin",
            },
          ]}
          style={{ padding: 0, margin: 0, maxWidth: 200 }}
        >
          <Select
            allowClear={false}
            className="custom-select"
            placeholder={
              <span style={{ color: "#343A40" }}>Loại bảo hiểm</span>
            }
          >
            {product?.map((item) => (
              <Option key={item?.id} value={item?.id}>
                {item.title}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <div style={{ marginTop: 20 }}>
          <Dragger
            listType="picture"
            multiple={true}
            disabled={loading}
            customRequest={() => { }} // Ngăn chặn gọi API tự động
            accept=".jpeg,.jpg,.png,.pdf,.mp4"
            showUploadList={false}
            style={{ minHeight: height }}
            fileList={fileList}
            onChange={(e) => {
              onFinish(e.fileList)
              setFileList([])
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: height }}>
              {
                !loading ?
                  <div>
                    <p style={{ marginTop: 15 }}>
                      <img src={Iupload} style={{ width: 24 }} />
                    </p>
                    <p style={{ marginTop: 10 }}>
                      <b className="ant-upload-text">
                        Kéo thả file vào đây hoặc
                      </b>
                    </p>
                    <p>
                      <b
                        style={{
                          color: "#0B7CE4",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        Tải file lên
                      </b>
                    </p>
                  </div> :
                  <div>
                    <LoadingOutlined style={{ fontSize: 20 }} />
                    <div>Đang tải file lên...</div>
                  </div>
              }
            </div>
          </Dragger>
          <div style={{ fontSize: 12, marginTop: 10 }}>
            <div><i>Các file định dạng: Jpeg, png, jpg, pdf, mp4</i></div>
            <div><i>Dung lượng file tối đa 10M</i></div>
          </div>
        </div>
      </Form>
    </>
  );
}

export default UploadFile;
