import React, { useEffect, useState, useContext, useRef } from "react";
import { Button, Modal, Form, Select, Empty, Row, Col } from "antd";
import Helper from "./../../../extra/helper";
import Service from "./../../../extra/getAPI";
import Check from "./../../../assets/images/salesManager/check-square-contained.png";
import UploadFileComponent from "./../../library/upload-multiple-file"
import { GlobalState } from "./../../../pages/kho-content/index";
import { AuthContext } from "./../../../context/AuthProvider";


function UploadFile(props) {
  const { setMediaImport } = useContext(GlobalState);
  const { product} = useContext(AuthContext);
  const { Option } = Select;
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const formRef = React.createRef();
  const [tabsActive, setTabsActive] = useState(2);
  const [formData, setFormData] = useState({ offset: 1 });
  const [images, setImages] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);

  const [total, setToTal] = useState('');
  const divRef = useRef();
  const datatab = [
    { id: 1, title: "Tải lên" },
    { id: 2, title: "Kho media" }
  ];

  //Chọn hình để chèn vào bài viết
  const toggleSelection = (id) => {
    setSelectedIds((prevSelectedIds) =>
      prevSelectedIds.includes(id)
        ? prevSelectedIds.filter((itemId) => itemId !== id)
        : [...prevSelectedIds, id]
    );
  };

  useEffect(() => {
    // console.log(selectedIds)
  }, [selectedIds])

  const onFinish = (val) => {
    form
      .validateFields()
      .then(async (values) => {
        const filteredImages = images.filter(image => selectedIds.includes(image.uid));
        const newFileArray = filteredImages.map(item => ({
          data: Helper._renderContent(item?.type, item?.url)
        }));
        setMediaImport(newFileArray);
        props.handleCancel();
      })
      .catch((err) => {
        console.error("err:", err);
      });
  };

  const handleScroll = () => {
    if (loading || (formData.offset * 24 > total)) return;
    window.scrollTo(0, 0);
    const divElement = divRef.current;
    if (divElement.scrollHeight - divElement.scrollTop === divElement.clientHeight) {
      // alert('You have reached the bottom of the div in the modal!');
      setFormData({
        ...formData,
        offset: +formData?.offset + 1,
      });
    }
  };

  const Getlist = (formData) => {
    setLoading(true);
    const data = new URLSearchParams({
      page: formData?.offset,
      limit: 24,
      search: formData?.search || "",
      product_id: formData?.product_id || "",
      // pbp_id: productsByProvider.find((item) => item.id === +formData?.product_id)?.id || "",
      type: formData?.type || ''
    });
    Service.apicall(
      "GET",
      `/api/v1/products-hub/media-gallery?${data.toString()}`
    )
      .then((res) => {
        if (res.data.statusCode === 200) {
          // setImages(res?.data?.result.data);
          setToTal(+res?.data?.result?.count)
          setImages((prev) => [...prev, ...res?.data?.result.data]);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const onChangForm = ({ data, name }) => {
    setFormData({
      ...formData,
      [name]: data,
    });
  };

  // Debounced tìm kiếm
  useEffect(() => {
    if (tabsActive === 1) return
    setLoading(true);
    const timeoutId = setTimeout(() => {
      // Gọi hàm
      Getlist(formData);
    }, 1500);
    return () => clearTimeout(timeoutId);
  }, [formData]);


  const css = {
    activeTabs: {
      padding: "15px 24px",
      borderBottom: "1px solid #3961A0",
      width: "fit-content",
      color: "#3961A0",
      fontWeight: 500,
      cursor: "pointer",
    },
    defaultTabs: {
      padding: "15px 24px",
      width: "fit-content",
      cursor: "pointer",
    },
  };

  return (
    <>
      <Modal
        footer={false}
        className="modal-custom-content"
        visible={props?.open}
        centered={true}
        closable={true}
        onCancel={props?.handleCancel}
        width={'991px'}
      >
        <Form
          ref={formRef}
          scrollToFirstError
          form={form}
          onFinish={onFinish}
          autoComplete="off"
        >
          <div style={{ fontSize: 18, fontWeight: 600, marginBottom: 10, marginTop: -13}}>Thêm media</div>
          <div
            style={{
              borderBottom: "1px solid #DCDCDC",
              display: "flex",
              alignItems: "center",
              marginBottom: 20
            }}
          >
            {datatab.map((item) => (
              <div
                onClick={() => {
                  setTabsActive(item.id);
                  if (item.id === 1) {
                    setToTal('');
                    setImages([]);
                    setFormData({ offset: 1 });
                    setSelectedIds([]);
                    form.resetFields(['product_id']);
                  }
                  if (item.id === 2) {
                    Getlist(formData);
                  }
                }}
                key={item.id}
                style={
                  tabsActive === item.id ? css.activeTabs : css.defaultTabs
                }
              >
                {item.title}
              </div>
            ))}
          </div>
          {/* Tab tải ảnh lên */}
          {
            tabsActive === 1 &&
            <>
              <UploadFileComponent
                height={430}
              />
            </>
          }
          {/* Tab kho media */}
          {
            tabsActive === 2 &&
            <>
              <div style={{ display: 'flex' }}>
                <Form.Item
                  name="type"
                  rules={[
                    {
                      required: false,
                      message: "",
                    },
                  ]}
                  style={{ padding: 0, margin: 0, minWidth: 200, marginRight: 10 }}
                >
                  <Select
                    allowClear={true}
                    className="custom-select"
                    placeholder={
                      <span style={{ color: "#343A40" }}>Loại tài liệu</span>
                    }
                    onChange={(e) => {
                      onChangForm({ data: e, name: "type" });
                      setImages([]);
                    }}
                  >
                    <Option value="Image">
                      Hình ảnh
                    </Option>
                    <Option value="Document">
                      Tài liệu
                    </Option>
                    <Option value="Video">
                      Video
                    </Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  name="product_id"
                  rules={[
                    {
                      required: false,
                      message: "",
                    },
                  ]}
                  style={{ padding: 0, margin: 0, minWidth: 200 }}
                >
                  <Select
                    allowClear={true}
                    className="custom-select"
                    placeholder={
                      <span style={{ color: "#343A40" }}>Loại bảo hiểm</span>
                    }
                    onChange={(e) => {
                      onChangForm({ data: e, name: "product_id" });
                      setImages([]);
                    }}
                  >
                    {product?.map((item) => (
                      <Option key={item?.id} value={item?.id}>
                        {item.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              {
                images.length > 0 &&
                <div ref={divRef} onScroll={handleScroll} style={{ marginTop: 20, height: 451, overflow: 'auto', overflowX: 'hidden' }}>
                  <Row gutter={[10, 10]}>
                    {
                      images.map((item) => (
                        <Col onClick={() => toggleSelection(item.uid)} style={{ position: 'relative', cursor: 'pointer' }} key={item.uid} xl={4} xs={24}>
                          <div style={{ width: '100%', height: '123px' }}>
                            {
                              item?.type === 'Image' &&
                              <img width={'100%'} height={'100%'} loading="lazy" src={item?.url} style={{ border: selectedIds.includes(item.uid) ? '2px solid #0B7CE4' : 'none' }} />
                            }
                            {
                              item?.type === 'Video' &&
                              <video style={{ border: selectedIds.includes(item.uid) ? '2px solid #0B7CE4' : 'none' }} width={'100%'} height={'100%'} controls>
                                <source src={item?.url} type="video/mp4" />
                              </video>
                            }
                            {
                              item?.type === 'Document' &&
                              <div style={{ width: '100%', height: '100%', position: 'relative' }}>
                                <embed onClick={() => toggleSelection(item.uid)} src={item?.url} style={{ marginRight: 5, zIndex: 1 }} type="application/pdf" width="100%" height="100%" />
                                <div style={{ width: '100%', height: 123, background: 'red', position: 'absolute', top: 0, opacity: 0 }}></div>
                              </div>
                            }
                          </div>
                          <img src={Check} width={19} style={{ position: 'absolute', right: 7, top: 3, display: selectedIds.includes(item.uid) ? '' : 'none' }} />
                        </Col>
                      ))
                    }
                  </Row>
                  {
                    loading &&
                    <div style={{ width: '100%', textAlign: 'center' }}>Đang tải...</div>
                  }
                </div>
              }
              {
                images.length === 0 &&
                <div style={{ marginTop: 20, height: 451, overflow: 'auto', overflowX: 'hidden' }}>
                  <div style={{ display: "flex", alignItems: 'center', width: '100%', justifyContent: 'center', height: '100%' }}>
                    <Empty description={loading ? "Đang tải..." : "Không có dữ liệu"} />
                  </div>
                </div>
              }
              <div
                style={{
                  textAlign: 'right',
                  marginTop: 20,
                  width: "100%",
                }}
              >
                <div>
                  <Button
                    htmlType="submit"
                    loading={loading}
                    disabled={selectedIds.length > 0 ? false : true}
                    style={{
                      background: "#0B7CE4",
                      color: "#ffffff",
                      textAlign: "center",
                      borderRadius: 6,
                      border: "1px solid #0B7CE4",
                      height: 40,
                    }}
                  >
                    <b>Chèn vào bài viết</b>
                  </Button>
                </div>
              </div>
            </>
          }
        </Form>
      </Modal>
    </>
  );
}

export default UploadFile;
