import {
  Row,
  Col,
  Button,
  Form,
  Radio,
  Space
} from "antd";
import React, { useEffect, useState, useContext, useRef } from "react";
import { AuthContext } from "./../../context/AuthProvider";
import { GlobalState } from "./index";

function XemBaiViet() {
  const [form] = Form.useForm();
  const formRef = React.createRef();
  const { product } = useContext(AuthContext);
  const { productsByProvider, detail } = useContext(GlobalState);
  const { setPage } = useContext(GlobalState);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (detail?.uid) {
      form.setFieldsValue({
        title: detail?.title,
        product_id: detail?.product_id,
        content: detail?.content
      })
    }
  }, []);

  const onFinish = (val) => {
    form
      .validateFields()
      .then(async (values) => {
        console.log(values);
      })
      .catch((err) => {
        console.error("err:", err);
      });
  };


  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          fontSize: 14,
          marginBottom: 10
        }}
      >
        <div style={{ fontWeight: 600, fontSize: 20 }}>
          {detail?.title}
        </div>
      </div>
      <Form
        ref={formRef}
        layout={"vertical"}
        scrollToFirstError
        form={form}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Row gutter={[10, 0]}>
          <Col xl={18} xs={24}>
            <div style={{ background: '#fff', padding: 20, minHeight: 518 }}>
              <div
                dangerouslySetInnerHTML={{
                  __html: detail?.content || "",
                }} />
            </div>
          </Col>
          <Col xl={6} xs={24}>
            <div style={{ background: '#fff', padding: 20, }}>
              <div style={{ fontWeight: 600, fontSize: 18 }}>
                Chuyên mục
              </div>
              <div style={{ marginTop: 20, maxHeight: 430, overflow: 'auto', overflowX: 'hidden', minHeight: 430 }}>
                <Form.Item
                  className="username"
                  style={{ marginBottom: 10 }}
                  name="product_id"
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập thông tin",
                    },
                  ]}
                >
                  <Radio.Group disabled>
                    <Space direction="vertical">
                      {product?.map((item) => (
                        <Radio key={item?.id} value={item?.id}>
                          {item.title}
                        </Radio>
                      ))}
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </div>
            </div>
          </Col>
          <Col xs={24}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                marginTop: 20,
                width: "100%",
              }}
            >
              <div>
                <Button
                  onClick={() => setPage(1)}
                  loading={false}
                  style={{
                    color: "#0B7CE4",
                    textAlign: "center",
                    borderRadius: 6,
                    border: "1px solid #0B7CE4",
                    height: 40,
                    marginRight: 10,
                  }}
                >
                  <b>Quay lại</b>
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default XemBaiViet;