import {
  Row,
  Col,
  Table,
  Button,
  Pagination,
  Form,
  Input,
  Select,
  DatePicker,
  message,
  List,
  Dropdown,
} from "antd";
import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "./../../../context/AuthProvider";
import { GlobalState } from "./index";
import Helper from "../../../extra/helper";
import Itaogoi from "./../../../assets/images/salesManager/itaogoi.png";
import Itimkiem from "./../../../assets/images/itimkiem.png";
import Ixoa from "./../../../assets/images/salesManager/trash-02.png";
import ThaotacA from "./../../../assets/images/salesManager/thaotacA.png";
import Ikichhoat from "./../../../assets/images/salesManager/ikichhoat.png";
import Ingunghoatdong from "./../../../assets/images/salesManager/ngunghoadong.png";
import moment from "moment";

function Danhsachmedia() {
  const [form] = Form.useForm();
  const formRef = React.createRef();
  const { Option } = Select;
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const { checkAccountGC, roleManagement } = useContext(AuthContext);
  const { page } = useContext(GlobalState);
  const [selectionType, setSelectionType] = useState('checkbox');
  const [openUpload, setOpenUpload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [arrDelete, setArrDelete] = useState([]);
  const [pageS, setPageS] = useState(0);
  const [formData, setFormData] = useState({
    offset: 1,
    createdFrom: moment(moment().subtract(1, "month"))
      .startOf("month")
      .format("YYYY-MM-DD"),
    createdTo: moment(moment().subtract(0, "month"))
      .startOf("month")
      .format("YYYY-MM-DD"),
  });

  const data = [
    {
      title: (
        <div
          onClick={() => {
            // setOpenConfirmActive(true);
            // message.warning('Chức năng đang xây dựng')
          }}
          style={{ cursor: "pointer" }}
        >
          Hoạt động
        </div>
      ),
      avatar: (
        <img
          // onClick={() => {
          //   setOpenConfirmActive(true);
          // }}
          src={Ikichhoat}
          width={25}
          style={{ cursor: "pointer" }}
        />
      ),
    },
    {
      title: (
        <div
          // onClick={() => setOpenConfirmDisable(true)}
          style={{ cursor: "pointer" }}
        >
          Ngừng hoạt động
        </div>
      ),
      avatar: (
        <img
          // onClick={() => {
          //   setOpenConfirmDisable(true);
          // }}
          src={Ingunghoatdong}
          width={25}
          style={{ cursor: "pointer" }}
        />
      ),
    }
  ];

  const Action = (
    <List
      min-width="100%"
      className="header-notifications-dropdown custom"
      itemLayout="horizontal"
      dataSource={data}
      renderItem={(item, i) => (
        <List.Item
        >
          <List.Item.Meta
            avatar={item.avatar}
            title={<span style={{ fontWeight: 500 }}>{item.title}</span>}
          />
        </List.Item>
      )}
    />
  );

  const columns = [
    {
      title: 'Tập tin',
      dataIndex: 'name',
      render: (text) => <div style={{ textAlign: 'left' }}>{text}</div>,
    },
    {
      title: 'Nhà bảo hiểm',
      dataIndex: 'address',
    },
    {
      title: 'Dung lượng',
      dataIndex: 'age',
    },
    {
      title: 'Tên sản phẩm',
      dataIndex: 'address',
    },
    {
      title: 'Loại bảo hiểm',
      dataIndex: 'address',
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'address',
    },
    {
      title: "",
      fixed: "right",
      align: "center",
      render: (_, data) => (
        <div style={{ width: "50px" }}>
          <Dropdown
            overlay={Action}
            trigger={["click"]}
          >
            <a className="ant-dropdown-link">
              <img
                src={ThaotacA}
                style={{ width: 25 }}
              />
            </a>
          </Dropdown>
        </div>
      ),
    },
  ].filter((item) => !item.hidden);

  const dataAccount = [
    {
      key: '1',
      name: 'John Brown',
      age: 32,
      address: 'New York No. 1 Lake Park',
    },
    {
      key: '2',
      name: 'Jim Green',
      age: 42,
      address: 'London No. 1 Lake Park',
    },
    {
      key: '3',
      name: 'Joe Black',
      age: 32,
      address: 'Sydney No. 1 Lake Park',
    },
    {
      key: '4',
      name: 'Disabled User',
      age: 99,
      address: 'Sydney No. 1 Lake Park',
    }
  ];

  const handleCancel = () => {
    setOpenUpload(false)
  }

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      setArrDelete(selectedRows);
    },
    getCheckboxProps: (record) => ({
      // disabled: record.name === 'Disabled User',
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  const onChangForm = ({ data, name }) => {
    setFormData({
      ...formData,
      [name]: data,
    });
  };

  // Debounced tìm kiếm
  useEffect(() => {
    setLoading(true);
    const timeoutId = setTimeout(() => {
      // Gọi hàm
      console.log(formData);
    }, 1500);
    return () => clearTimeout(timeoutId);
  }, [formData]);

  const css = {
    input: {
      color: "#3A3A3A",
      zIndex: 1,
      fontSize: 12,
      boxShadow: "none",
      padding: "0px 10px",
      borderRadius: 4,
      width: "100%",
      border: "1px solid #d2d2d2",
    },
    titleInput: { color: "#646D69", marginBottom: 3 },
    FormItemsHolder: {
      width: "100%",
      borderRadius: 4,
      margin: "5px 0px",
      padding: 0,
    },
    button: {
      fontSize: 12,
      background: "#007185",
      color: "#fff",
      borderRadius: 4,
      height: 33,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
    },
    activeTabs: {
      padding: "15px 24px",
      borderBottom: "3px solid #3961A0",
      width: "fit-content",
      color: "#3961A0",
      fontWeight: 600,
      cursor: "pointer",
    },
    defaultTabs: {
      padding: "15px 24px",
      width: "fit-content",
      cursor: "pointer",
    },
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          fontSize: 14,
          marginBottom: 20
        }}
      >
        <div style={{ fontWeight: 600, fontSize: 20 }}>
          Quản lý tài khoản
        </div>
      </div>
      <div>
        <Row gutter={[10, 0]}>
          <Col
            xl={24}
            xs={24}
            style={{
              background: "#fff",
              padding: "10px 20px",
              marginBottom: 10,
            }}
          >
            <Row style={{ alignItems: "center" }}>
              <Col xl={20} xs={24}>
                <b> Chọn khung thời gian: Từ</b>{" "}
                <DatePicker
                  placeholder="Chọn tháng"
                  format={"MM.YYYY"}
                  style={{ borderRadius: 6 }}
                  defaultValue={moment().subtract(1, "month")}
                  onChange={(e) => {
                    onChangForm({ data: e, name: "createdFrom" });
                  }}
                  picker="month"
                  monthCellRender={(date) => {
                    const month = moment(date).format("MM");
                    return <div className="pickerCustom"> Tháng {month}</div>;
                  }}
                />{" "}
                <b>Đến</b>{" "}
                <DatePicker
                  placeholder="Chọn tháng"
                  format={"MM.YYYY"}
                  style={{ borderRadius: 6 }}
                  defaultValue={moment().subtract(0, "month")}
                  onChange={(e) => {
                    onChangForm({ data: e, name: "createdTo" });
                  }}
                  picker="month"
                  monthCellRender={(date) => {
                    const month = moment(date).format("MM");
                    return <div className="pickerCustom"> Tháng {month}</div>;
                  }}
                />
              </Col>
              {(+roleManagement?.report?.order === 1 ||
                +roleManagement?.manager?.order === 1) && (
                  <Col xl={4} xs={0}>
                    <div
                      style={{ justifyContent: "flex-end", display: "flex" }}
                    >
                      <Button
                        disabled={false}
                        loading={false}
                        style={{
                          padding: "0px 20px",
                          borderRadius: 5,
                          width: "fit-content",
                          display: "flex",
                          alignItems: "center",
                          fontWeight: 500,
                          height: 32,
                          background: '#3961A0',
                          color: '#fff'
                        }}
                      >
                        Thêm tài khoản
                      </Button>
                    </div>
                  </Col>
                )}
            </Row>
          </Col>
          <Col xl={4} md={12} xs={24}>
            <Form.Item
              name="productId"
              rules={[
                {
                  required: false,
                  message: "",
                },
              ]}
              style={{ ...css.FormItemsHolder, marginTop: 6 }}
            >
              <Select
                allowClear={true}
                className="custom-select"
                placeholder={
                  <span style={{ color: "#343A40" }}>Loại quyền truy cập</span>
                }
              >
                {/* {product.map((item) => (
                    <Option key={item?.id} value={item?.id}>
                      {item.title}
                    </Option>
                  ))} */}
              </Select>
            </Form.Item>
          </Col>
          <Col xl={4} md={12} xs={24}>
            <Form.Item
              name="productId"
              rules={[
                {
                  required: false,
                  message: "",
                },
              ]}
              style={{ ...css.FormItemsHolder, marginTop: 6 }}
            >
              <Select
                allowClear={true}
                className="custom-select"
                placeholder={
                  <span style={{ color: "#343A40" }}>Loại tài khoản</span>
                }
              >
                {/* {product.map((item) => (
                    <Option key={item?.id} value={item?.id}>
                      {item.title}
                    </Option>
                  ))} */}
              </Select>
            </Form.Item>
          </Col>
          <Col xl={4} md={12} xs={24}>
            <Form.Item
              name="productId"
              rules={[
                {
                  required: false,
                  message: "",
                },
              ]}
              style={{ ...css.FormItemsHolder, marginTop: 6 }}
            >
              <Select
                allowClear={true}
                className="custom-select"
                placeholder={
                  <span style={{ color: "#343A40" }}>Công ty bảo hiểm</span>
                }
              >
                {/* {product.map((item) => (
                    <Option key={item?.id} value={item?.id}>
                      {item.title}
                    </Option>
                  ))} */}
              </Select>
            </Form.Item>
          </Col>
          <Col xl={9} md={12} xs={24}>
            <Form.Item
              name="search"
              rules={[
                {
                  required: false,
                  message: "Vui lòng nhập đầy đủ thông tin.",
                },
              ]}
              style={{ ...css.FormItemsHolder }}
            >
              <Input
                allowClear
                onChange={(e) =>
                  onChangForm({ data: e.target.value, name: "search" })
                }
                className="custom-input"
                prefix={
                  <img
                    src={Itimkiem}
                    alt="Global Care icon"
                    width={14}
                    height={14}
                  />
                }
                style={{
                  ...css.input,
                  maxWidth: 375,
                  fontSize: 12,
                }}
                placeholder="Tìm kiếm..."
              />
            </Form.Item>
          </Col>
        </Row>
      </div>
      <div className="tabled2">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Table
              // loading={loading}
              columns={columns}
              dataSource={dataAccount}
              pagination={false}
              className="ant-border-spaces khuyenmai"
              bordered={false}
              scroll={
                {
                  x: 'auto'
                }
              }
              showHeader={true}
            />
            <div style={{ textAlign: "right", margin: "10px" }}>
              <Pagination
                className="paginateCustom2"
                total={pageS || 20}
                pageSize={20}
                showTotal={(total, range) => (
                  <span
                    style={{ color: "#3961A0" }}
                  >{`Hiển thị ${range[0]}-${range[1]} trong ${total}`}</span>
                )}
                defaultCurrent={1}
                defaultPageSize={0}
                // className="paginateCustom"
                onChange={(e) => {
                  onChangForm({ data: e, name: "offset" });
                }}
                size="small"
              // scroll={{
              //   y: 240,
              // }}
              />
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Danhsachmedia;
