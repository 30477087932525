import { useState, useEffect, useContext } from "react";
import { Menu, Row, Col } from "antd";
import { useLocation, NavLink } from "react-router-dom";
import logo from "./../../assets/images/salesManager/logoGc.png";
import Isanpham from "./../../assets/images/salesManager/isanpham.png";
import Ikhuyenmai from "./../../assets/images/salesManager/ikhuyenmai.png";
import Iquanlydon from "../../assets/images//salesManager/iquanlydon.png";
import IDashboard from "../../assets/images//salesManager/iDashboard.png";
import IMedia from "../../assets/images//salesManager/IMedia.png";
import IContent from "../../assets/images//salesManager/iKhocontent.png";
import ITaikhoan from "../../assets/images//salesManager/itaikhoan.png";
import Idangxuat2 from "../../assets/images/imenudangxuat61.png";
import History from "./../../routes/history";
import { AuthContext } from "./../../context/AuthProvider";
import { Animated } from "react-animated-css";

function Sidenav({ color }) {
  const { setIndexPage, roleManagement, checkAccountGC } = useContext(AuthContext);
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");
  const selectedKeys = pathname.substring(pathname.lastIndexOf('/') + 1).replace(/[^a-zA-Z0-9]/g, '');
  const [namePage, setNamePage] = useState(page);
  const { SubMenu } = Menu;
  const dataSub = [
    {
      id: 1,
      namePage: "suckhoe",
      title: "BH Sức khoẻ",
      link: "/san-pham/suc-khoe",
    },
  ];

  const dataSubAccount = [
    {
      id: 1,
      namePage: "phanquyen",
      title: "Phân quyền",
      link: "/quan-ly-tai-khoan/phan-quyen",
    },
    {
      id: 2,
      namePage: "danhsachtaikhoan",
      title: "Danh sách tài khoản",
      link: "/quan-ly-tai-khoan/danh-sach-tai-khoan",
    },
  ];

  const SignOut = () => {
    localStorage.clear();
    History.push("/sign-in");
  };

  // useEffect(() => {
  //   setNamePage('san-pham')
  //   console.log()
  // }, []);

  return (
    <>
      <div style={{ textAlign: "center" }}>
        <img
          onClick={() => {
            History.push("/dashboard");
            setNamePage("dashboard");
          }}
          src={logo}
          alt=""
          style={{ width: 115, cursor: "pointer" }}
        />
      </div>
      <hr className="gradient-line" />
      <Menu
        openKeys={namePage}
        defaultSelectedKeys={[selectedKeys]}
        theme="light"
        mode="inline"
        inlineCollapsed={true}
      >
        <Menu.Item
          onClick={() => {
            setNamePage("dashboard");
            setIndexPage(1);
          }}
          key="dashboard"
        >
          <NavLink to="/dashboard">
            <span>
              <img
                src={IDashboard}
                style={{ width: "30px", marginRight: 5 }}
              />
            </span>
            <span className="label">Dashboard</span>
          </NavLink>
        </Menu.Item>
        {(roleManagement?.view?.product === 1 ||
          roleManagement?.manager?.product === 1) && (
            <SubMenu
              key={"san-pham"}
              onTitleClick={() => setNamePage("san-pham")}
              icon={
                <span
                  // className="icon"
                  style={{
                    background: "",
                  }}
                >
                  <img src={Isanpham} style={{ width: "30px", marginRight: 5 }} />
                </span>
              }
              title={
                <span style={{ margin: 0 }} className="label">
                  Sản phẩm
                </span>
              }
            >
              {dataSub.map((item) => (
                <Menu.Item
                  onClick={() => setIndexPage(1)}
                  onTitleClick={() => {
                    setNamePage(item.namePage);
                  }}
                  key={item.namePage}
                >
                  <NavLink to={item.link}>
                    <span
                      style={{ paddingLeft: 33 }}
                      className="label label-menu-itenm-sub"
                    >
                      {item.title}
                    </span>
                  </NavLink>
                </Menu.Item>
              ))}
            </SubMenu>
          )}
        {(roleManagement?.view?.discount === 1 || roleManagement?.manager?.discount === 1) && (
          <Menu.Item
            onClick={() => {
              setNamePage("khuyenmai");
              setIndexPage(1);
            }}
            key="khuyenmai"
          >
            <NavLink to="/khuyen-mai">
              <span>
                <img
                  src={Ikhuyenmai}
                  style={{ width: "30px", marginRight: 5 }}
                />
              </span>
              <span className="label">Khuyến mãi</span>
            </NavLink>
          </Menu.Item>
        )}
        {(roleManagement?.view?.order === 1 || roleManagement?.manager?.order === 1) && (
          <Menu.Item
            onClick={() => {
              setNamePage("quanlydon");
              setIndexPage(1);
            }}
            key="quanlydon"
          >
            <NavLink to="/quan-ly-don">
              <span>
                <img
                  src={Iquanlydon}
                  style={{ width: "30px", marginRight: 5 }}
                />
              </span>
              <span className="label">Quản lý đơn</span>
            </NavLink>
          </Menu.Item>
        )}
        <Menu.Item
          onClick={() => {
            setNamePage("khomedia");
            setIndexPage(1);
          }}
          key="khomedia"
        >
          <NavLink to="/kho-media">
            <span>
              <img
                src={IMedia}
                style={{ width: "30px", marginRight: 5 }}
              />
            </span>
            <span className="label">Kho media</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            setNamePage("khocontent");
            setIndexPage(1);
          }}
          key="khocontent"
        >
          <NavLink to="/kho-content">
            <span>
              <img
                src={IContent}
                style={{ width: "30px", marginRight: 5 }}
              />
            </span>
            <span className="label">Kho content</span>
          </NavLink>
        </Menu.Item>
        {/* {
          checkAccountGC &&
          <SubMenu
            key={"quan-ly-tai-khoan"}
            onTitleClick={() => setNamePage("quan-ly-tai-khoan")}
            icon={
              <span
                // className="icon"
                style={{
                  background: "",
                }}
              >
                <img src={ITaikhoan} style={{ width: "30px", marginRight: 5 }} />
              </span>
            }
            title={
              <span style={{ margin: 0 }} className="label">
                Quản lý tài khoản
              </span>
            }
          >
            {dataSubAccount.map((item) => (
              <Menu.Item
                onClick={() => setIndexPage(1)}
                onTitleClick={() => {
                  setNamePage(item.namePage);
                }}
                key={item.namePage}
              >
                <NavLink to={item.link}>
                  <span
                    style={{ paddingLeft: 33 }}
                    className="label label-menu-itenm-sub"
                  >
                    {item.title}
                  </span>
                </NavLink>
              </Menu.Item>
            ))}
          </SubMenu>
        } */}
        <Menu.Item key="dangxuat">
          <Row style={{ padding: 0 }}>
            <Col
              onClick={() => SignOut()}
              style={{ marginLeft: 16, marginTop: 10 }}
              xs={24}
              md={24}
              lg={0}
            >
              <span>
                <img
                  src={Idangxuat2}
                  style={{ width: "30px", marginRight: 5 }}
                />
              </span>
              <span style={{ color: "#141414" }}>Đăng xuất</span>
            </Col>
          </Row>
        </Menu.Item>
      </Menu>
    </>
  );
}

export default Sidenav;
