import React, { useContext } from "react";
import Helper from "./../../../extra/helper";
import { AuthContext } from "./../../../context/AuthProvider";

function ExportDetail(props) {
  const { provider } = useContext(AuthContext);

  const totalBenefits =
    props?.data?.insured_persons?.benefitsDetail?.reduce(
      (accumulator, item) => {
        return +accumulator + +item?.benefits?.total_benefits;
      },
      0
    );

  return (
    <div style={{ display: "none" }}>
      <table
        className="collapse-border"
        id={"report-detail"}
        data-cols-width="10,30, 30, 30, 40, 20, 40, 40, 40,40, 40,40, 40, 40,40, 40,40, 40"
        border={"1px solid red"}
      >
        <thead style={{ fontSize: "13" }}>
          <tr>
            <th
              // data-b-a-c="FFFFFFFF"
              colSpan={18}
              data-f-name={"Times new roman"}
              data-f-sz={"24"}
              data-a-wrap={true}
              data-a-h={"center"}
              // data-b-a-s={"thin"}
              data-f-bold={true}
            >
              Chi tiết đơn hàng
            </th>
          </tr>
          <tr>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
            >
              STT
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
            >
              SỐ HĐBH/CNBH
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
            >
              Tên sản phẩm
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
            >
              Gói sản phẩm
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
            >
              HỌ VÀ TÊN NĐBH
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
            >
              NGÀY SINH
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
            >
              CCCD/CMND
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
            >
              SĐT
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
            >
              Email
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
            >
              Địa chỉ
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
            >
              MQH với chủ HĐ
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
            >
              HỌ VÀ TÊN chủ HĐ
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
            >
              NGÀY SINH
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
            >
              CCCD/CMND
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
            >
              Giới tính
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
            >
              HIỆU LỰC TỪ
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
            >
              HIỆU LỰC ĐẾN
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
            >
              Phí bảo hiểm
            </th>
            {/* <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
            >
              Tổng quyền lợi
            </th> */}
          </tr>
        </thead>
        <tbody style={{ fontSize: "13" }}>
          {props?.data?.map((item, index) => {
            return (
              <tr key={index} style={{ fontSize: "13" }}>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >
                  1
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >
                  {item?.cert_num}
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >
                  {item?.product_by_provider_product_title}
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >
                  {item?.packages?.title || item?.packages?.[0]?.title}
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >
                  {
                    item?.insured_persons?.length > 0 ?
                      item?.insured_persons?.map((person, index) => (
                        <React.Fragment key={index}>
                          {person?.insuredName
                            ?.split(" ")
                            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                            .join(" ")}
                          {index < item?.insured_persons?.length - 1 && <br />}
                        </React.Fragment>
                      )) :
                      item?.insured_persons?.insuredName
                        ?.split(" ")
                        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                        .join(" ")
                  }
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >
                  {
                    item?.insured_persons?.length > 0 ?
                      item?.insured_persons?.map((person, index) => (
                        <React.Fragment key={index}>
                          {Helper._formatDayNoHour(person?.insuredDob)}
                          {index < item?.insured_persons?.length - 1 && <br />}
                        </React.Fragment>
                      )) :
                      Helper._formatDayNoHour(item?.insured_persons?.insuredDob)
                  }
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >
                  {
                    item?.insured_persons?.length > 0 ?
                      item?.insured_persons?.map((person, index) => (
                        <React.Fragment key={index}>
                          {person?.insuredPrivateId}
                          {index < item?.insured_persons?.length - 1 && <br />}
                        </React.Fragment>
                      )) :
                      item?.insured_persons?.insuredPrivateId
                  }
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >
                  {
                    item?.insured_persons?.length > 0 ?
                      item?.insured_persons?.map((person, index) => (
                        <React.Fragment key={index}>
                          {person?.insuredPhone}
                          {index < item?.insured_persons?.length - 1 && <br />}
                        </React.Fragment>
                      )) :
                      item?.insured_persons?.insuredPhone
                  }
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >
                  {
                    item?.insured_persons?.length > 0 ?
                      item?.insured_persons?.map((person, index) => (
                        <React.Fragment key={index}>
                          {person?.insuredEmail}
                          {index < item?.insured_persons?.length - 1 && <br />}
                        </React.Fragment>
                      )) :
                      item?.insured_persons?.insuredEmail
                  }
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >
                  {
                    item?.insured_persons?.length > 0 ?
                      item?.insured_persons?.map((person, index) => (
                        <React.Fragment key={index}>
                          {person?.insuredAddress}
                          {index < item?.insured_persons?.length - 1 && <br />}
                        </React.Fragment>
                      )) :
                      item?.insured_persons?.insuredAddress
                  }
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >
                  {
                    item?.insured_persons?.length > 0 ?
                      item?.insured_persons?.map((person, index) => (
                        <React.Fragment key={index}>
                          {person?.relationshipWithBuyerName || 'Bản thân'}
                          {index < item?.insured_persons?.length - 1 && <br />}
                        </React.Fragment>
                      )) :
                      item?.insured_persons?.relationshipWithBuyerName || 'Bản thân'
                  }
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >
                  {item?.buyer_name
                    ?.split(" ")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ")}
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >
                  {Helper._formatDayNoHour(item?.buyer_dob)}
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >
                  {item?.buyer_private_id}
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >
                  {item?.buyer_gender === 1 ? "Nam" : "Nữ"}
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >
                  {Helper._formatDayNoHour(item?.begin_date)}
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >
                  {Helper._formatDayNoHour(item?.end_date)}
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >
                  {item?.fees}
                </td>
                {/* <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >
                  {totalBenefits}
                </td> */}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default React.memo(ExportDetail);
