import moment from "moment";

function getParamUrlByKey(keyparame) {
  let search = window.location.search;
  let arr = search.split("?");
  let params = new URLSearchParams(arr[1]);
  let res;

  if (keyparame) {
    res = params.get(keyparame);
  }
  return res;
}

function _formatMoney(str) {
  if (str) {
    return str
      .toString()
      .replace(/\./, ",")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }
  return 0;
}

export function _formatMoney2(str) {
  if (str) {
    return str
      .toString()
      .replace(/\./, ",")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }
  return 0;
}

export function _formatNumber(str) {
  if (str) {
    return str.toString().replace(/,/g, ".");
  }
  return 0;
}

export function _getDuration(str) {
  if (str) {
    var arr = str.split(" ");
    var arr1 = arr[0].split(":");
    var arr2 = arr[1].split("/");
    var hour = arr1[0];
    var minute = arr1[1];
    var day = arr2[0];
    var month = arr2[1];
    var year = arr2[2];
    var result = {
      hour,
      minute,
      day,
      month,
      year,
    };
  }
  return result;
}

function _getTPlatformParamUrl() {
  let search = window.location.search;
  let arr = search.split("?");
  let params = new URLSearchParams(arr[1]);
  let platform = params.get("platform");
  return platform;
}

function _formatDayNoHour(str) {
  if (str) {
    return moment(str).format("DD/MM/YYYY");
  }
  return "";
}

function _formatDayNoHour2(str) {
  if (str) {
    return moment(str).format("YYYY-MM-DD");
  }
  return "";
}

function _formatDay(str) {
  if (str) {
    return moment(str).format("DD/MM/YYYY HH:mm:ss");
  }
  return "";
}

function _formatDay2(str) {
  if (str) {
    return moment(str).format("YYYY-MM-DD HH:mm:ss");
  }
  return "";
}

function _formatDay3(str) {
  if (str) {
    return moment(str).format("MM.YYYY");
  }
  return "";
}

function _formatDay4(str) {
  if (str) {
    return moment(str).format("MM/YYYY");
  }
  return "";
}


function _formatH(str) {
  if (str) {
    return moment(str).format("h:mm a");
  }
  return "";
}

function _renderData(value) {
  var data = value;
  var newdata = [];
  for (var i = 0; i < parseInt(data.length); i++) {
    var item = {
      insuredName: data[i]?.fullname,
      insuredPhone: data[i]?.phoneNumber,
      insuredDob: _formatDayNoHour(data[i]?.birthday),
      insuredEmail: data[i]?.email,
      insuredPrivateId: data[i]?.privateId,
    };
    newdata.push(item);
  }
  return newdata;
}

function _checkEndate(value) {
  var toDay = _formatDay(new Date());
  var endDate = _formatDay(value);
  var data = moment(toDay, "DD/MM/YYYY").isAfter(moment(endDate, "DD/MM/YYYY"));
  console.log(data);
  return data;
}

function capitalize(str) {
  if (!str) {
    return "";
  }
  var splitStr = str.toLowerCase().split(" ");
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(" ");
}

function getValueKeyText(value) {
  let arr = value.split("/");
  let data = {
    id: arr[0],
    value: arr[1],
  };
  return data;
}

export function generateCodeInput(str) {
  if (str) {
    return str
      .toLowerCase() // Chuyển thành chữ thường
      .normalize("NFD") // Chuẩn hóa chuỗi Unicode
      .replace(/[^a-z0-9\s-đĐ]/gi, "") // Loại bỏ các ký tự đặc biệt
      .replace(/[\u0300-\u036f]/g, "") // Loại bỏ dấu
      .replace(/\s+/g, "-") // Thay thế khoảng trắng bằng dấu gạch ngang
      .replace(/-+/g, "-") // Loại bỏ dấu gạch ngang liên tiếp
      .replace(/^-+|-+$/g, ""); // Loại bỏ dấu gạch ngang ở đầu và cuối chuỗi
  }
  return "";
}

export function generateCodeInput2(str) {
  if (str) {
    return str
      .toLowerCase() // Chuyển thành chữ thường
      .normalize("NFD") // Chuẩn hóa chuỗi Unicode
      .replace(/[^a-z0-9\s-đĐ]/gi, "") // Loại bỏ các ký tự đặc biệt
      .replace(/[\u0300-\u036f]/g, "") // Loại bỏ dấu
      .replace(/\s+/g, "_") // Thay thế khoảng trắng bằng dấu gạch ngang
      .replace(/-+/g, "-") // Loại bỏ dấu gạch ngang liên tiếp
      .replace(/^-+|-+$/g, ""); // Loại bỏ dấu gạch ngang ở đầu và cuối chuỗi
  }
  return "";
}

// Rút gọn số
export function formatNumber2(num) {
  if (Math.abs(num) >= 1.0e+9) {
    return (Math.abs(num) / 1.0e+9).toFixed(1) + "B";
  } else if (Math.abs(num) >= 1.0e+6) {
    return (Math.abs(num) / 1.0e+6).toFixed(1) + "M";
  } else if (Math.abs(num) >= 1.0e+3) {
    return (Math.abs(num) / 1.0e+3).toFixed(1) + "K";
  } else {
    return num;
  }
};

function createArrayNumber(number) {
  const array = [];
  for (let i = 1; i <= number; i++) {
    array.push(i);
  }
  return array;
}

function _renderTypeFile(key) {
  switch (key) {
    case 'image/png':
      return "Image"
    case 'image/jpeg':
      return "Image"
    case 'application/pdf':
      return "Document"
    case 'video/mp4':
      return "Video"
    default:
      break;
  }
}

function _renderContent(key, link) {
  switch (key) {
    case 'Video':
      return `<br /><div class="ckeditor-html5-video" style="text-align:left"><video controls="controls" controlslist="nodownload" src=${link} width="50%">&nbsp;</video></div><br />`
    case 'Document':
      return `<br /><a href=${link} target="_blank">Link tải tài liệu</a><br />`
    case 'Image':
      return `<br /><img alt="" src=${link} style="width:50%" /><br />`
    default:
      break;
  }
}

const variable = {
  getParamUrlByKey,
  _formatMoney,
  _getTPlatformParamUrl,
  _renderData,
  _formatDayNoHour,
  _formatH,
  _formatDay,
  _checkEndate,
  capitalize,
  _formatMoney2,
  _formatDayNoHour2,
  _formatNumber,
  _getDuration,
  getValueKeyText,
  generateCodeInput,
  _formatDay2,
  _formatDay3,
  _formatDay4,
  formatNumber2,
  createArrayNumber,
  generateCodeInput2,
  _renderTypeFile,
  _renderContent
};
export default variable;
