import {
  Row,
  Col,
  Button,
  Form,
  Input,
  Radio,
  Space,
  message
} from "antd";
import Service from "./../../extra/getAPI";
import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "./../../context/AuthProvider";
import { GlobalState } from "./index";
import Helper from "../../extra/helper";
import Ithemanh from "./../../assets/images/salesManager/Ithemanh.png";
import UploadFile from "./../../components/modal/kho-content/uploadFile";
import TextEdit from "./../../components/library/ckEditor4-content";

function TaoBaiViet() {
  const [form] = Form.useForm();
  const formRef = React.createRef();
  const { checkAccountGC, product } = useContext(AuthContext);
  const { setPage, productsByProvider, detail, setDetail, mediaImport } = useContext(GlobalState);
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [cotent, setContent] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
    if (detail?.uid) {
      form.setFieldsValue({
        title: detail?.title,
        product_id: detail?.product_id,
        content: detail?.content
      })
    }
  }, []);

  const handleCancel = () => {
    setOpenUpload(false);
    setOpenView(false);
  }

  const onFinish = (val) => {
    form
      .validateFields()
      .then(async (values) => {
        const data =
        {
          "product_id": values?.product_id,
          "product_title": product.find((item) => item.id === +values.product_id)?.title,
          "title": values?.title,
          "content": values?.content,
          "media_gallery_uids": [],
          // "pbp_id": productsByProvider.find((item) => item.id === +values.product_id)?.id,
          // "pbp_title": productsByProvider.find((item) => item.id === +values.product_id)?.product_title,
        }
        if (detail?.uid) {
          Update(data, detail?.uid);
        } else {
          Create(data);
        }
      })
      .catch((err) => {
        console.error("err:", err);
      });
  };

  const Create = (data) => {
    setLoading(true);
    Service.apicall("POST", `/api/v1/products-hub/content-gallery`, data)
      .then((res) => {
        setLoading(false);
        if (res.data.statusCode === 200) {
          message.success('Tạo bài viết thành công!');
          setPage(1);
          setDetail({});
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const Update = (data, uid) => {
    setLoading(true);
    Service.apicall("PUT", `/api/v1/products-hub/content-gallery/${uid}`, data)
      .then((res) => {
        setLoading(false);
        if (res.data.statusCode === 200) {
          message.success('Cập nhật bài viết thành công!');
          setPage(1);
          setDetail({});
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <>
      {
        openUpload &&
        <UploadFile open={true} handleCancel={handleCancel} />
      }
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          fontSize: 14,
          marginBottom: 10
        }}
      >
        <div style={{ fontWeight: 600, fontSize: 20 }}>
          Tạo bài viết
        </div>
      </div>
      <Form
        ref={formRef}
        layout={"vertical"}
        scrollToFirstError
        form={form}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Row gutter={[10, 0]}>
          <Col xl={18} xs={24}>
            <div style={{ background: '#fff', padding: 20 }}>
              <div>
                <Form.Item
                  className="username iputTextArea"
                  style={{ marginBottom: 10 }}
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập thông tin",
                    },
                  ]}
                >
                  <Input.TextArea
                    allowClear
                    style={{
                      resize: "none",
                      padding: "10px 10px",
                      borderRadius: 7,
                    }}
                    rows={1}
                    autoSize
                    placeholder="Nhập tiêu đề"
                  />
                </Form.Item>
              </div>
              <div
                onClick={() => setOpenUpload(true)}
                style={{
                  padding: "7px 11px",
                  background: "#fff",
                  width: "fit-content",
                  color: "#3961A0",
                  borderRadius: 5,
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  border: '1px solid #3961A0',
                  marginTop: 20
                }}
              >
                <img src={Ithemanh} style={{ width: 18, marginRight: 11 }} />
                Thêm media
              </div>
              <div style={{ marginTop: 20 }}>
                <TextEdit
                  setValue={(e) => {
                    form.setFieldsValue({ content: e })
                  }}
                  defaultData={
                    detail?.content || ''
                  }
                  disable={false}
                  mediaImport={mediaImport}
                />
                <Form.Item
                  name={"content"}
                  style={{ margin: "-30px 0px 0px 0px", zIndex: 1 }}
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập thông tin",
                    },
                  ]}
                >
                  <Input style={{ display: "none" }} />
                </Form.Item>
              </div>
            </div>
          </Col>
          <Col xl={6} xs={24}>
            <div style={{ background: '#fff', padding: 20, minHeight: 430 }}>
              <div style={{ fontWeight: 600, fontSize: 18 }}>
                Chuyên mục
              </div>
              <div style={{ marginTop: 20 }}>
                <Form.Item
                  className="username"
                  style={{ marginBottom: 10 }}
                  name="product_id"
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập thông tin",
                    },
                  ]}
                >
                  <Radio.Group>
                    <Space direction="vertical">
                      {product?.map((item) => (
                        <Radio key={item?.id} value={item?.id}>
                          {item.title}
                        </Radio>
                      ))}
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </div>
            </div>
          </Col>
          <Col xs={24}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                marginTop: 20,
                width: "100%",
              }}
            >
              <div>
                <Button
                  onClick={() => { setPage(1); setDetail({}); }}
                  disabled={loading}
                  style={{
                    color: "#0B7CE4",
                    textAlign: "center",
                    borderRadius: 6,
                    border: "1px solid #0B7CE4",
                    height: 40,
                    marginRight: 10,
                  }}
                >
                  <b>{detail?.uid ? 'Quay lại' : 'Bỏ qua'}</b>
                </Button>
                <Button
                  htmlType="submit"
                  loading={loading}
                  style={{
                    background: "#0B7CE4",
                    color: "#ffffff",
                    textAlign: "center",
                    borderRadius: 6,
                    border: "1px solid #0B7CE4",
                    height: 40,
                  }}
                >
                  <b>Lưu</b>
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default TaoBaiViet;