import React, { useEffect, useState, useContext } from "react";
import { message } from "antd";
import "antd/dist/antd.css";
import Service from "./../../extra/getAPI";
import DanhSachContent from "./danh-sach-content";
import TaoBaiViet from "./tao-bai-viet";
import XemBaiViet from "./xem-bai-viet";
import { AuthContext } from "./../../context/AuthProvider";
export const GlobalState = React.createContext();

function KhoContent() {
  const { setNamePage, indexPage, setIndexPage } = useContext(AuthContext);
  const [page, setPage] = useState(1);
  const [mediaImport, setMediaImport] = useState([]);
  const [detail, setDetail] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
    setNamePage("Kho content");
    setIndexPage(0);
  }, []);

  useEffect(() => {
    setPage(1);
  }, [indexPage === 1]);

  const PageView = () => {
    switch (page) {
      case 1:
        return <DanhSachContent />;
      case 2:
        return <TaoBaiViet />;
      case 3:
        return <XemBaiViet />;
    }
  };


  return (
    <GlobalState.Provider
      value={{
        page, setPage,
        detail, setDetail,mediaImport, setMediaImport
      }}
    >
      <div>{PageView()}</div>
    </GlobalState.Provider>
  );
}

export default KhoContent;
