import React, { useEffect, useState, useContext } from "react";
import { message } from "antd";
import "antd/dist/antd.css";
import DanhSachTaiKhoan from "./danh-sach-tai-khoan";
import { AuthContext } from "./../../../context/AuthProvider";
export const GlobalState = React.createContext();

function KhoMedia() {
  const { setNamePage, indexPage, setIndexPage } = useContext(AuthContext);
  const [page, setPage] = useState(1);

  useEffect(() => {
    window.scrollTo(0, 0);
    setNamePage("Quản lý tài khoản");
    setIndexPage(0);
  }, []);

  useEffect(() => {
    setPage(1);
  }, [indexPage === 1]);

  const PageView = () => {
    switch (page) {
      case 1:
        return <DanhSachTaiKhoan/>;
    }
  };

  return (
    <GlobalState.Provider
      value={{
        page, setPage
      }}
    >
      <div>{PageView()}</div>
    </GlobalState.Provider>
  );
}

export default KhoMedia;
