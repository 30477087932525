import {
  Row,
  Col,
  Table,
  Pagination,
  Form,
  Input,
  Select,
  message,
  Popconfirm
} from "antd";
import { Animated } from "react-animated-css";
import Service from "./../../extra/getAPI";
import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "./../../context/AuthProvider";
import { GlobalState } from "./index";
import Helper from "../../extra/helper";
import Itaogoi from "./../../assets/images/salesManager/itaogoi.png";
import Itimkiem from "./../../assets/images/itimkiem.png";
import Ixoa from "./../../assets/images/salesManager/trash-02.png";
import IDowload from "./../../assets/images/salesManager/Vector.svg";
import UploadFile from "./../../components/modal/kho-media/uploadFile";


function Danhsachmedia() {
  const { Option } = Select;
  const { checkAccountGC, provider, product } = useContext(AuthContext);
  const { productsByProvider } = useContext(GlobalState);
  const [selectionType, setSelectionType] = useState('checkbox');
  const [openUpload, setOpenUpload] = useState(false);
  const [formData, setFormData] = useState({ offset: 1 });
  const [pageS, setPageS] = useState(0);
  const [loading, setLoading] = useState(false);
  const [arrDelete, setArrDelete] = useState([]);
  const [listMedias, seListMedias] = useState([]);
  const [selectedRowUIDs, setSelectedRowUIDs] = useState([]);

  const columns = [
    {
      title: 'Tập tin',
      render: (_, data) => (
        <div onClick={() => window.open(data?.url, '_blank')} style={{ textAlign: 'left', width: 'max-content', display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
          {
            data?.type === 'Image' &&
            <img src={data?.url} style={{ marginRight: 5 }} alt="Example Image" width="58" height="63" />
          }
          {
            data?.type === 'Video' &&
            <video style={{ marginRight: 5 }} width="58" height="63" controls>
              <source src={data?.url} type="video/mp4" />
            </video>
          }
          {
            data?.type === 'Document' &&
            <embed src={data?.url} style={{ marginRight: 5 }} type="application/pdf" width="58" height="63" />
          }
          {data?.name}
        </div>
      ),
    },
    {
      title: 'Nhà bảo hiểm',
      render: (data) => <div style={{ textAlign: 'left', minWidth: 100 }}>{data?.provider_title || '-'}</div>,
    },
    {
      title: 'Dung lượng',
      render: (_, data) => (
        <div style={{ textAlign: "left", minWidth: 100 }}>
          {Helper._formatMoney2((data?.size / (1024 * 1024)).toFixed(3))} MB
        </div>
      ),
    },
    // {
    //   title: 'Tên sản phẩm',
    //   render: (_, data) => (
    //     <div style={{ textAlign: "left", minWidth: 100 }}>
    //       {data?.pbp_title || '-'}
    //     </div>
    //   ),
    // },
    {
      title: 'Loại bảo hiểm',
      render: (_, data) => (
        <div style={{ textAlign: "left", minWidth: 100 }}>
          {data?.product_title || '-'}
        </div>
      ),
    },
    {
      title: 'Ngày tạo',
      render: (_, data) => (
        <div style={{ textAlign: "left" }}>
          {Helper._formatDayNoHour(data?.created_at)}
        </div>
      ),
    },
    {
      title: '',
      hidden: !checkAccountGC ? true : false,
      fixed: "right",
      render: (_, data) => (
        <div onClick={() => window.open(data?.url, '_blank')} style={{ textAlign: "center", cursor: 'pointer', width: 15 }}>
          <img src={IDowload} alt="tai-ve" width="15" height="15" />
        </div>
      ),
    },
  ].filter((item) => !item.hidden);

  const handleCancel = () => {
    setOpenUpload(false);
    Getlist(formData)
  }

  const Getlist = (formData) => {
    setLoading(true);
    const data = new URLSearchParams({
      page: formData?.offset,
      limit: 20,
      search: formData?.search || "",
      product_id: formData?.product_id || "",
      // pbp_id: productsByProvider.find((item) => item.id === +formData?.product_id)?.id || "",
      type: formData?.type || '',
      provider: formData?.provider_id || ''
    });
    Service.apicall(
      "GET",
      `/api/v1/products-hub/media-gallery?${data.toString()}`
    )
      .then((res) => {
        if (res.data.statusCode === 200) {
          seListMedias(res?.data?.result.data);
          setPageS(+res?.data?.result?.count);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    selectedRowKeys: selectedRowUIDs,
    onChange: (selectedRowKeys) => {
      setSelectedRowUIDs(selectedRowKeys); // Cập nhật state khi người dùng chọn các hàng
    },
  };

  const onChangForm = ({ data, name }) => {
    setFormData({
      ...formData,
      [name]: data,
    });
  };

  // Debounced tìm kiếm
  useEffect(() => {
    setLoading(true);
    const timeoutId = setTimeout(() => {
      // Gọi hàm
      Getlist(formData);
    }, 1500);
    return () => clearTimeout(timeoutId);
  }, [formData]);

  const confirmDelete = (selectedRowUIDs) => {
    DeleteAction(selectedRowUIDs, 0);
  };

  const DeleteAction = (selectedRowUIDs, indexUpload) => {
    Delete(selectedRowUIDs, indexUpload);
  }

  const Delete = (selectedRowUIDs, indexUpload) => {
    const uid = selectedRowUIDs[indexUpload];
    setLoading(true);
    Service.apicall(
      "PUT",
      `/api/v1/products-hub/media-gallery/${uid}/delete`
    )
      .then((res) => {
        if (res.data.statusCode === 200) {
          if (indexUpload === (selectedRowUIDs.length - 1)) {
            message.success("Xoá thành công!");
            setLoading(false);
            Getlist(formData);
            setSelectedRowUIDs([]);
            return
          }
          DeleteAction(selectedRowUIDs, indexUpload + 1);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  //xet chiều cao window
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {
        openUpload &&
        <UploadFile open={true} handleCancel={handleCancel} />
      }
      <Animated style={{ width: '100%', zIndex: 1 }} animationIn="slideInUp" animationInDuration={700} isVisible={true}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontSize: 14,
            marginBottom: 20
          }}
        >
          <div style={{ fontWeight: 600, fontSize: 20 }}>
            Kho media
          </div>
          <div
            onClick={() => setOpenUpload(true)}
            style={{
              padding: "7px 11px",
              background: "#0B7CE4",
              width: "fit-content",
              color: "#fff",
              borderRadius: 5,
              display: checkAccountGC ? "none" : "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <img src={Itaogoi} style={{ width: 18, marginRight: 11 }} />
            Tải file
          </div>
        </div>
        <Row gutter={[10, 10]}>
          <Col xl={(checkAccountGC || selectedRowUIDs.length === 0) ? 5 : 7} md={12} xs={24}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Popconfirm
                className="customPopup"
                title={`Bạn muốn xoá ${selectedRowUIDs.length} file?`}
                onConfirm={() => confirmDelete(selectedRowUIDs)}
                // onCancel={cancel}
                okText={'Xác nhận'}
                cancelText="Huỷ"
              >
                <div style={{ cursor: 'pointer', background: '#fff', display: selectedRowUIDs.length > 0 ? 'flex' : 'none', marginRight: 10, padding: '6px 20px', borderRadius: 5, border: '1px solid #D2D2D2', alignItems: 'center', justifyContent: 'center' }}>
                  <img src={Ixoa} width={17} height={17} style={{ marginRight: 5 }} /> Xoá
                </div>
              </Popconfirm>
              <div style={{ width: '100%' }}>
                <Form.Item
                  name="type"
                  rules={[
                    {
                      required: false,
                      message: "",
                    },
                  ]}
                  style={{ padding: 0, margin: 0 }}
                >
                  <Select
                    allowClear={true}
                    className="custom-select"
                    placeholder={
                      <span style={{ color: "#343A40" }}>Loại tài liệu</span>
                    }
                    onChange={(e) => {
                      onChangForm({ data: e, name: "type" });
                    }}
                  >
                    <Option value="Image">
                      Hình ảnh
                    </Option>
                    <Option value="Document">
                      Tài liệu
                    </Option>
                    <Option value="Video">
                      Video
                    </Option>
                  </Select>
                </Form.Item>
              </div>
            </div>
          </Col>
          <Col xl={5} md={12} xs={24}>
            <Form.Item
              name="product_id"
              rules={[
                {
                  required: false,
                  message: "",
                },
              ]}
              style={{ padding: 0, margin: 0 }}
            >
              <Select
                allowClear={true}
                className="custom-select"
                placeholder={
                  <span style={{ color: "#343A40" }}>Loại bảo hiểm</span>
                }
                onChange={(e) => {
                  onChangForm({ data: e, name: "product_id" });
                }}
              >
                {product?.map((item) => (
                  <Option key={item?.id} value={item?.id}>
                    {item.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {
            checkAccountGC &&
            <Col xl={5} md={12} xs={24}>
              <Form.Item
                name="provider_id"
                rules={[
                  {
                    required: false,
                    message: "",
                  },
                ]}
                style={{ padding: 0, margin: 0 }}
              >
                <Select
                  allowClear={true}
                  className="custom-select"
                  placeholder={
                    <span style={{ color: "#343A40" }}>Công ty bảo hiểm</span>
                  }
                  onChange={(e) => {
                    onChangForm({ data: e, name: "provider_id" });
                  }}
                >
                  {provider.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          }
          <Col xl={7} md={12} xs={24}>
            <Form.Item
              name="search"
              rules={[
                {
                  required: false,
                  message: "Vui lòng nhập đầy đủ thông tin.",
                },
              ]}
              style={{ padding: 0, margin: 0 }}
            >
              <Input
                onChange={(e) => {
                  onChangForm({ data: e.target.value, name: "search" });
                }}
                allowClear
                className="custom-input"
                prefix={
                  <img
                    src={Itimkiem}
                    alt="Global Care icon"
                    width={14}
                    height={14}
                  />
                }
                placeholder="Tìm kiếm..."
              />
            </Form.Item>
          </Col>
        </Row>
      </Animated>
      <Animated style={{ width: '100%', zIndex: 1 }} animationIn="slideInUp" animationInDuration={800} isVisible={true}>
        <div style={{ background: '#fff', width: '100%', marginTop: 15 }}>
          <div className="tabled2">
            <Table
              loading={loading}
              rowKey="uid"
              className="ant-border-spaces khuyenmai"
              rowSelection={!checkAccountGC ? {
                type: selectionType,
                ...rowSelection,
              } : ''}
              // size="small"
              pagination={false}
              columns={columns}
              dataSource={listMedias}
              scroll={
                listMedias?.length > 0 ?
                  {
                    y: windowHeight > 550 ? "calc(100vh - 366px)" : "51vh",
                    x: 'auto'
                  } :
                  {
                    x: 'auto'
                  }
              }
            />
          </div>
          <div style={{ textAlign: "right", margin: "10px" }}>
            <Pagination
              className="paginateCustom2"
              total={pageS || 20}
              pageSize={20}
              showTotal={(total, range) => (
                <span
                  style={{ color: "#3961A0" }}
                >{`Hiển thị ${range[0]}-${range[1]} trong ${total}`}</span>
              )}
              defaultCurrent={1}
              defaultPageSize={0}
              // className="paginateCustom"
              onChange={(e) => {
                onChangForm({ data: e, name: "offset" });
              }}
              size="small"
            // scroll={{
            //   y: 240,
            // }}
            />
          </div>
        </div>
      </Animated>
    </>
  );
}

export default Danhsachmedia;
