import React, { useEffect, useState, useContext } from "react";
import LogoGC from "./../../assets/images/salesManager/logoGc.png";
import { Animated } from "react-animated-css";
import "antd/dist/antd.css";
import { Layout, Row, Col, Form, Input, Checkbox, Button, message } from "antd";
import Service from "../../extra/getAPI";
import { AuthContext } from "./../../context/AuthProvider";
import history from "../../routes/history";

const { Content } = Layout;

const Sign = () => {
  const { GetProfile, roleManagement } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [openSigin, setOpenSigin] = useState(false);
  const [openForgot, setOpenForgot] = useState(false);
  const [openRememberpass, setOpenRememberpass] = useState(false);

  useEffect(() => {
    localStorage.clear();
  }, []);

  const onFinish = (values) => {
    setLoading(true);
    SignIn(values);
  };

  useEffect(() => {
    const login = localStorage.getItem("login");
    if (
      roleManagement?.manager &&
      JSON.stringify(roleManagement?.manager) !== "{}" && login
    ) {
      history.push("/dashboard");
      // if (
      //   roleManagement?.manager?.product === 1 ||
      //   roleManagement?.view?.product === 1
      // ) {
      //   history.push("/san-pham/suc-khoe");
      // } else if (
      //   roleManagement?.manager?.discount === 1 ||
      //   roleManagement?.view?.discount === 1
      // ) {
      //   history.push("/khuyen-mai");
      // } else if (
      //   roleManagement?.manager?.order === 1 ||
      //   roleManagement?.view?.order === 1
      // ) {
      //   history.push("/quan-ly-don");
      // }
    }
  }, [roleManagement]);

  // Đăng nhập
  const SignIn = (values) => {
    setLoading(true);
    const data = {
      username: values.username,
      password: values.password,
    };
    Service.apicallNotRequireLogin("POST", `/api/v1/portal/auth/sign-in`, data)
      .then((res) => {
        if (res.data.statusCode === 200) {
          localStorage.setItem("login", "done");
          localStorage.setItem("dataUser", JSON.stringify(res.data.result));
          GetProfile();
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinishRemember = (values) => {
    setLoading(true);
    ForgotPass(values);
  };

  const ForgotPass = (values) => {
    setLoading(true);
    const data = {
      info: values.info,
    };
    Service.apicallNotRequireLogin(
      "POST",
      `/api/v1/user/portal/forgot-password`,
      data
    )
      .then((res) => {
        setLoading(false);
        if (res.data.statusCode === 200) {
          message.success("Vui lòng kiểm tra email để lấy mật khẩu mới!");
          setOpenRememberpass(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const onFinishFailedRemeber = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <Row
        style={{
          height: "100vh",
          background: "#F7FBFF",
          alignItems: "center",
          overflow: "hidden",
        }}
      >
        {/* Đăng nhập */}
        {!openRememberpass && (
          <Col xs={24}>
            <Animated
              animationIn="bounceInDown"
              animationInDuration={1000}
              isVisible={true}
            >
              <Row
                style={{
                  margin: "0px auto",
                  padding: "3%",
                  borderRadius: 16,
                  background: "#fff",
                  boxShadow: "0px 0px 16px 0px #0000000A",
                  maxWidth: 798,
                  fontSize: 16,
                }}
              >
                <Col style={{ textAlign: "center" }} xs={0} xl={10}>
                  <img src={LogoGC} style={{ width: "60%", marginRight: 45 }} />
                </Col>
                <Col style={{ textAlign: "center" }} xs={24} xl={0}>
                  <img src={LogoGC} style={{ width: 200, marginBottom: 15 }} />
                </Col>
                <Col xs={24} xl={14}>
                  <div
                    style={{
                      fontSize: 24,
                      fontWeight: 600,
                      color: "#1A427D",
                    }}
                  >
                    Đăng nhập
                  </div>
                  <div style={{ marginBottom: 10 }}>
                    Đăng nhập bằng tài khoản của Global Care của bạn.
                  </div>
                  <Form
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    layout="vertical"
                    className="row-col"
                  >
                    <Form.Item
                      className="username"
                      name="username"
                      rules={[
                        {
                          required: true,
                          message: "Vui lòng nhập đầy đủ thông tin.",
                        },
                        // {
                        //   message: "Vui lòng nhập đúng email.",
                        //   pattern: /\S+@\S+\.\S+/,
                        // },
                      ]}
                    >
                      <Input
                        placeholder="Username"
                        style={{ fontWeight: 400 }}
                      />
                    </Form.Item>

                    <Form.Item
                      className="username"
                      name="password"
                      // style={{ marginTop: -5 }}
                      rules={[
                        {
                          required: true,
                          message: "Vui lòng nhập đầy đủ thông tin",
                        },
                        {
                          min: 6,
                          message: "Mật khẩu tối thiểu phải đủ 6 ký tự",
                        },
                      ]}
                    >
                      <Input.Password
                        className="customPass"
                        style={{ padding: "0px 10px", borderRadius: 5 }}
                        placeholder="Password"
                      />
                    </Form.Item>

                    <Form.Item
                      name="remember"
                      className="aligin-center"
                      valuePropName="checked"
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                          marginTop: -15,
                        }}
                      >
                        <div>
                          <Checkbox.Group>
                            <Checkbox value="rules">Nhớ mật khẩu</Checkbox>
                          </Checkbox.Group>
                        </div>
                        <div
                          onClick={() => setOpenRememberpass(true)}
                          style={{
                            color: "#1A427D",
                            cursor: "pointer",
                          }}
                        >
                          Quên mật khẩu
                        </div>
                      </div>
                    </Form.Item>

                    <Form.Item style={{ textAlign: "center", marginTop: -20 }}>
                      <Button
                        loading={loading}
                        type="primary"
                        htmlType="submit"
                        style={{
                          background: "#1A427D",
                          fontSize: 16,
                          border: "1px solid  #007185",
                          width: "100%",
                          fontWeight: 600,
                          borderRadius: 8,
                          height: 45,
                        }}
                      >
                        Đăng nhập
                      </Button>
                    </Form.Item>
                  </Form>
                </Col>
              </Row>
            </Animated>
          </Col>
        )}
        {/* Quên mật khẩu */}
        {openRememberpass && (
          <Col xs={24}>
            <Animated
              animationIn="bounceInUp"
              animationInDuration={1000}
              isVisible={true}
            >
              <Row
                style={{
                  margin: "0px auto",
                  padding: "3%",
                  borderRadius: 16,
                  background: "#fff",
                  boxShadow: "0px 0px 16px 0px #0000000A",
                  maxWidth: 798,
                  fontSize: 16,
                }}
              >
                <Col style={{ textAlign: "center" }} xs={0} xl={10}>
                  <img src={LogoGC} style={{ width: "60%", marginRight: 45 }} />
                </Col>
                <Col style={{ textAlign: "center" }} xs={24} xl={0}>
                  <img src={LogoGC} style={{ width: 200, marginBottom: 15 }} />
                </Col>
                <Col xs={24} xl={14}>
                  <div
                    style={{
                      fontSize: 24,
                      fontWeight: 600,
                      color: "#1A427D",
                    }}
                  >
                    Quên mật khẩu
                  </div>
                  <Form
                    onFinish={onFinishRemember}
                    onFinishFailed={onFinishFailedRemeber}
                    layout="vertical"
                    className="row-col"
                  >
                    <Form.Item
                      className="username"
                      name="info"
                      rules={[
                        {
                          required: true,
                          message: "Vui lòng nhập đầy đủ thông tin.",
                        },
                        {
                          message: "Vui lòng nhập đúng email, số điện thoại.",
                          pattern:
                            /\S+@\S+\.\S+|((09|03|07|08|05)+([0-9]{8})\b)/,
                        },
                      ]}
                    >
                      <Input
                        placeholder="Email/ Số điện thoại"
                        style={{ fontWeight: 400, marginTop: 10 }}
                      />
                    </Form.Item>
                    <Form.Item style={{ textAlign: "center" }}>
                      <Button
                        loading={loading}
                        type="primary"
                        htmlType="submit"
                        style={{
                          background: "#1A427D",
                          fontSize: 15,
                          border: "1px solid  #007185",
                          width: "100%",
                          fontWeight: 600,
                          borderRadius: 8,
                          height: 40,
                        }}
                      >
                        Đặt lại mật khẩu
                      </Button>
                    </Form.Item>
                  </Form>
                  <div
                    style={{
                      color: "#3A3A3A",
                      textAlign: "center",
                      marginTop: -10,
                    }}
                  >
                    Bạn đã có tài khoản?{" "}
                    <span
                      style={{
                        cursor: "pointer",
                        fontWeight: 600,
                        color: "#233A74",
                        marginLeft: 6,
                        fontSize: 15,
                        borderRadius: 8,
                      }}
                      onClick={() => setOpenRememberpass(false)}
                    >
                      Đăng nhập
                    </span>
                  </div>
                </Col>
              </Row>
            </Animated>
          </Col>
        )}
      </Row>
    </>
  );
};

export default Sign;
