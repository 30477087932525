import React, { useState, useEffect, useRef } from "react";
import Service from "./../extra/getAPI";
import "antd/dist/antd.css";
import { message } from "antd";

export const AuthContext = React.createContext();
export default function AuthProvider({ children }) {
  const [profile, setProfileGlobal] = useState({});
  const [detail, setDetail] = useState({});
  const [agency, setAgency] = useState([]);
  const [age, setAge] = useState([]);
  const [file, setFile] = useState(null);
  const [dataOCR, setDataOCR] = useState(null);
  const [namepage, setNamePage] = useState("Sản phẩm");
  const [indexPage, setIndexPage] = useState(0);
  const [checkAccountGC, setCheckAccountGC] = useState(false); // nếu là account của GC là true
  // Phân quyền hệ thống
  const [roleManagement, setRoleManagement] = useState({});
  // Công ty bảo hiểm
  const [provider, setProvider] = useState([]);
  const [province, setProvince] = useState([]);
  const [district, setDistrict] = useState([]);
  const [product, setProduct] = useState([]);

  useEffect(() => {
    const login = localStorage.getItem("login");
    if (login) {
      getAge();
      GetProfile();
      getProvider();
      getProvince();
      getProducts();
    }
  }, [localStorage.getItem("login")]);

  // Lấy danh sách công ty bảo hiểm
  const getProvider = () => {
    Service.apicall("GET", `/api/v1/products-hub/health/portal/provider`)
      .then((res) => {
        if (res.data.statusCode === 200) {
          setProvider(res?.data?.result);
        }
      })
      .catch((err) => { });
  };

  // Lấy danh sách tuổi
  const getAge = () => {
    Service.apicall("GET", `/api/v1/products-hub/health/portal/age/list`)
      .then((res) => {
        if (res.data.statusCode === 200) {
          setAge(res?.data?.result?.ageList);
        }
      })
      .catch((err) => { });
  };

  // Lấy danh sách tỉnh, thành phố
  const getProvince = () => {
    Service.apicall("GET", `/api/v1/products-hub/health/portal/province`)
      .then((res) => {
        if (res.data.statusCode === 200) {
          setProvince(res?.data?.result);
        }
      })
      .catch((err) => { });
  };

    // Lấy danh sách tỉnh thành
    const getDistrict = (id) => {
      Service.apicall("GET", `/api/v1/products-hub/health/portal/province/${id}/district`)
        .then((res) => {
          if (res.data.statusCode === 200) {
            setDistrict(res?.data?.result);
          }
        })
        .catch((err) => { });
    };

      // Lấy danh sách sản phẩm
  const getProducts = () => {
    Service.apicall("GET", `/api/v1/discount/products`)
      .then((res) => {
        if (res.data.statusCode === 200) {
          setProduct(res.data.result);
        } else {
          setProduct([]);
        }
      })
      .catch((err) => {
        setProduct([]);
      });
  };

  // Lấy thông tin tài khoản
  const GetProfile = () => {
    Service.apicall(
      "GET",
      `/api/v1/user/portal
    `
    )
      .then((res) => {
        if (res.data.statusCode === 200) {
          const role_code = res.data.result.role_code;
          const rolesManagement = res.data.result.userPortalRolesManagement;
          setProfileGlobal(res.data.result);
          if (
            rolesManagement?.manager &&
            JSON.stringify(rolesManagement?.manager) !== "{}"
          ) {
            setRoleManagement({
              manager: res.data.result.userPortalRolesManagement?.manager,
            });
          } else {
            setRoleManagement(res.data.result.userPortalRolesManagement);
          }
          if (
            role_code === "gc_sa" ||
            role_code === "gc_admin" ||
            role_code === "gc_staff"
          ) {
            setCheckAccountGC(true);
          } else {
            setCheckAccountGC(false);
          }
        }
      })
      .catch((err) => { });
  };

  return (
    <AuthContext.Provider
      value={{
        profile,
        detail,
        setDetail,
        file,
        setFile,
        setProfileGlobal,
        GetProfile,
        dataOCR,
        setDataOCR,
        namepage,
        setNamePage,
        age,
        getAge,
        checkAccountGC,
        indexPage,
        setIndexPage,
        roleManagement,
        provider,
        getProvider,
        province,
        district,
        getDistrict,
        setDistrict,
        product
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
