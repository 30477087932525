import React, { useContext } from "react";
import { Modal} from "antd";
import UploadFileComponent from "./../../library/upload-multiple-file.js"
import { GlobalState } from "./../../../pages/kho-media/index.js";

function UploadFile(props) {
  const { productsByProvider } = useContext(GlobalState);

  return (
    <>
      <Modal
        footer={false}
        className="modal-custom"
        visible={props?.open}
        centered={true}
        closable={true}
        onCancel={props?.handleCancel}
        style={{ borderRadius: 10, maxWidth: "600px" }}
      >
        <div style={{ fontSize: 18, fontWeight: 600, marginBottom: 10, marginTop: -13}}>Tải file lên</div>
        <UploadFileComponent
          productsByProvider={productsByProvider}
          height={141}
        />
      </Modal>
    </>
  );
}

export default UploadFile;
