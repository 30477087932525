import React, { useEffect, useState, useContext } from "react";
import "antd/dist/antd.css";
import Service from "./../../extra/getAPI";
import Thongke from "./thong-ke";
import { AuthContext } from "./../../context/AuthProvider";
export const GlobalState = React.createContext();

function IndexDonHang() {
  const { setNamePage, indexPage } = useContext(AuthContext);
  const [page, setPage] = useState(1);
  const [product, setProduct] = useState([]);
  const [productsByProvider, setProductsByProvider] = useState([]);
  const [detail, setDetail] = useState({});
  const iconChart = {
    luotxem: 
    `<span style="margin-right: 5px">
      <svg width="15" height="13" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.2945 10.688H1.77665V0.241595C1.77665 0.167934 1.71638 0.107666 1.64272 0.107666H0.705218C0.631557 0.107666 0.571289 0.167934 0.571289 0.241595V11.7595C0.571289 11.8331 0.631557 11.8934 0.705218 11.8934H13.2945C13.3682 11.8934 13.4284 11.8331 13.4284 11.7595V10.822C13.4284 10.7483 13.3682 10.688 13.2945 10.688ZM3.54785 8.10488C3.59975 8.15677 3.68345 8.15677 3.73703 8.10488L6.05232 5.8013L8.18848 7.95086C8.24038 8.00276 8.32575 8.00276 8.37765 7.95086L12.9881 3.34204C13.04 3.29014 13.04 3.20476 12.9881 3.15287L12.3252 2.48992C12.3 2.465 12.266 2.45101 12.2306 2.45101C12.1952 2.45101 12.1612 2.465 12.136 2.48992L8.28558 6.33869L6.15276 4.19249C6.12759 4.16756 6.0936 4.15358 6.05818 4.15358C6.02275 4.15358 5.98876 4.16756 5.96359 4.19249L2.88658 7.25108C2.86165 7.27625 2.84767 7.31024 2.84767 7.34567C2.84767 7.38109 2.86165 7.41508 2.88658 7.44026L3.54785 8.10488Z" fill="#3961A0" />
      </svg>
    </span>`,
    donbaohiem: 
    `<span style="margin-right: 5px">
      <svg width="15" height="13" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.2945 10.688H1.77665V0.241595C1.77665 0.167934 1.71638 0.107666 1.64272 0.107666H0.705218C0.631557 0.107666 0.571289 0.167934 0.571289 0.241595V11.7595C0.571289 11.8331 0.631557 11.8934 0.705218 11.8934H13.2945C13.3682 11.8934 13.4284 11.8331 13.4284 11.7595V10.822C13.4284 10.7483 13.3682 10.688 13.2945 10.688ZM3.54785 8.10488C3.59975 8.15677 3.68345 8.15677 3.73703 8.10488L6.05232 5.8013L8.18848 7.95086C8.24038 8.00276 8.32575 8.00276 8.37765 7.95086L12.9881 3.34204C13.04 3.29014 13.04 3.20476 12.9881 3.15287L12.3252 2.48992C12.3 2.465 12.266 2.45101 12.2306 2.45101C12.1952 2.45101 12.1612 2.465 12.136 2.48992L8.28558 6.33869L6.15276 4.19249C6.12759 4.16756 6.0936 4.15358 6.05818 4.15358C6.02275 4.15358 5.98876 4.16756 5.96359 4.19249L2.88658 7.25108C2.86165 7.27625 2.84767 7.31024 2.84767 7.34567C2.84767 7.38109 2.86165 7.41508 2.88658 7.44026L3.54785 8.10488Z" fill="#069D27" />
      </svg>
    </span>`,
    doanhso: 
    `<span style="margin-right: 5px">
      <svg width="15" height="13" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.2945 10.688H1.77665V0.241595C1.77665 0.167934 1.71638 0.107666 1.64272 0.107666H0.705218C0.631557 0.107666 0.571289 0.167934 0.571289 0.241595V11.7595C0.571289 11.8331 0.631557 11.8934 0.705218 11.8934H13.2945C13.3682 11.8934 13.4284 11.8331 13.4284 11.7595V10.822C13.4284 10.7483 13.3682 10.688 13.2945 10.688ZM3.54785 8.10488C3.59975 8.15677 3.68345 8.15677 3.73703 8.10488L6.05232 5.8013L8.18848 7.95086C8.24038 8.00276 8.32575 8.00276 8.37765 7.95086L12.9881 3.34204C13.04 3.29014 13.04 3.20476 12.9881 3.15287L12.3252 2.48992C12.3 2.465 12.266 2.45101 12.2306 2.45101C12.1952 2.45101 12.1612 2.465 12.136 2.48992L8.28558 6.33869L6.15276 4.19249C6.12759 4.16756 6.0936 4.15358 6.05818 4.15358C6.02275 4.15358 5.98876 4.16756 5.96359 4.19249L2.88658 7.25108C2.86165 7.27625 2.84767 7.31024 2.84767 7.34567C2.84767 7.38109 2.86165 7.41508 2.88658 7.44026L3.54785 8.10488Z" fill="#FFA21F" />
      </svg>
    </span>`,
    tuchoidon: 
    `<span style="margin-right: 5px">
      <svg width="15" height="13" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.2945 10.688H1.77665V0.241595C1.77665 0.167934 1.71638 0.107666 1.64272 0.107666H0.705218C0.631557 0.107666 0.571289 0.167934 0.571289 0.241595V11.7595C0.571289 11.8331 0.631557 11.8934 0.705218 11.8934H13.2945C13.3682 11.8934 13.4284 11.8331 13.4284 11.7595V10.822C13.4284 10.7483 13.3682 10.688 13.2945 10.688ZM3.54785 8.10488C3.59975 8.15677 3.68345 8.15677 3.73703 8.10488L6.05232 5.8013L8.18848 7.95086C8.24038 8.00276 8.32575 8.00276 8.37765 7.95086L12.9881 3.34204C13.04 3.29014 13.04 3.20476 12.9881 3.15287L12.3252 2.48992C12.3 2.465 12.266 2.45101 12.2306 2.45101C12.1952 2.45101 12.1612 2.465 12.136 2.48992L8.28558 6.33869L6.15276 4.19249C6.12759 4.16756 6.0936 4.15358 6.05818 4.15358C6.02275 4.15358 5.98876 4.16756 5.96359 4.19249L2.88658 7.25108C2.86165 7.27625 2.84767 7.31024 2.84767 7.34567C2.84767 7.38109 2.86165 7.41508 2.88658 7.44026L3.54785 8.10488Z" fill="#F3001D" />
      </svg>
    </span>`,
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    setNamePage("Dashboard");
    getProducts();
    getProductsByProvider();
  }, []);

  useEffect(() => {
    setPage(1);
  }, [indexPage === 1]);

  // Lấy danh sách sản phẩm
  const getProducts = () => {
    Service.apicall("GET", `/api/v1/discount/products`)
      .then((res) => {
        if (res.data.statusCode === 200) {
          setProduct(res.data.result);
        } else {
          setProduct([]);
        }
      })
      .catch((err) => {
        setProduct([]);
      });
  };

  // Lấy danh sách sản phẩm
  const getProductsByProvider = (productTypeId) => {
    Service.apicall(
      "GET",
      `/api/v1/products-hub/health/portal/product-by-provider`
    )
      .then((res) => {
        if (res.data.statusCode === 200) {
          setProductsByProvider(res.data.result.productByProvider);
        } else {
          setProductsByProvider([]);
        }
      })
      .catch((err) => {
        setProductsByProvider([]);
      });
  };

  const PageView = () => {
    switch (page) {
      case 1:
        return <Thongke />;
    }
  };

  return (
    <GlobalState.Provider
      value={{
        setPage,
        page,
        product,
        getProductsByProvider,
        productsByProvider,
        detail,
        setDetail,
        iconChart
      }}
    >
      <div>{PageView()}</div>
    </GlobalState.Provider>
  );
}

export default IndexDonHang;
