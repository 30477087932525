import {
  Row,
  Col,
  Table,
  Pagination,
  Form,
  Input,
  Select,
  message,
  Popconfirm
} from "antd";
import { Animated } from "react-animated-css";
import Service from "./../../extra/getAPI";
import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "./../../context/AuthProvider";
import { GlobalState } from "./index";
import Helper from "../../extra/helper";
import Itaogoi from "./../../assets/images/salesManager/itaogoi.png";
import Itimkiem from "./../../assets/images/itimkiem.png";
import Ixoa from "./../../assets/images/salesManager/trash-02.png";
import UploadFile from "./../../components/modal/kho-media/uploadFile";
import ThaotacA from "./../../assets/images/salesManager/thaotacA.png";


function DanhSachContent() {
  const { Option } = Select;
  const { checkAccountGC, provider, setIndexPage, product} = useContext(AuthContext);
  const { setPage, setDetail } = useContext(GlobalState);
  const [selectionType, setSelectionType] = useState('checkbox');
  const [openUpload, setOpenUpload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [arrDelete, setArrDelete] = useState([]);
  const [formData, setFormData] = useState({ offset: 1 });
  const [pageS, setPageS] = useState(0);
  const [listContents, seListContents] = useState([]);
  const [selectedRowUIDs, setSelectedRowUIDs] = useState([]);

  const columns = [
    {
      title: 'Tiêu đề',
      render: (_, data) => (
        <div style={{ textAlign: "left", width: 200 }}>
          <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
            {data?.title}
          </div>
        </div>
      ),
    },
    {
      title: 'Nhà bảo hiểm',
      render: (text) => <div style={{ textAlign: 'left', minWidth: 100 }}>{text?.provider_title || '-'}</div>,
    },
    {
      title: 'Chuyên mục',
      render: (text) => <div style={{ textAlign: 'left', whiteSpace: "normal", wordWrap: "break-word", minWidth: 100 }}>{text?.product_title}</div>,
    },
    {
      title: 'Ngày tạo',
      render: (text) => <div style={{ textAlign: 'left' }}>{Helper._formatDayNoHour(text?.created_at)}</div>
    },
  ];

  const handleCancel = () => {
    setOpenUpload(false)
  }

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    selectedRowKeys: selectedRowUIDs,
    onChange: (selectedRowKeys) => {
      setSelectedRowUIDs(selectedRowKeys); // Cập nhật state khi người dùng chọn các hàng
    },
  };

  const confirmDelete = (selectedRowUIDs) => {
    DeleteAction(selectedRowUIDs, 0);
  };

  const DeleteAction = (selectedRowUIDs, indexUpload) => {
    Delete(selectedRowUIDs, indexUpload);
  }

  const Delete = (selectedRowUIDs, indexUpload) => {
    const uid = selectedRowUIDs[indexUpload];
    setLoading(true);
    Service.apicall(
      "PUT",
      `/api/v1/products-hub/content-gallery/${uid}/delete`
    )
      .then((res) => {
        if (res.data.statusCode === 200) {
          if (indexUpload === (selectedRowUIDs.length - 1)) {
            message.success("Xoá thành công!");
            setLoading(false);
            Getlist(formData);
            setSelectedRowUIDs([]);
            return
          }
          DeleteAction(selectedRowUIDs, indexUpload + 1);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const Getlist = (formData) => {
    setLoading(true);
    const data = new URLSearchParams({
      page: formData?.offset,
      limit: 20,
      search: formData?.search || "",
      product_id: formData?.product_id || "",
      // pbp_id: productsByProvider.find((item) => item.id === +formData?.product_id)?.id || "",
      provider: formData?.provider_id || ''
    });
    Service.apicall(
      "GET",
      `/api/v1/products-hub/content-gallery?${data.toString()}`
    )
      .then((res) => {
        if (res.data.statusCode === 200) {
          seListContents(res?.data?.result.data);
          setPageS(+res?.data?.result?.count);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const onChangForm = ({ data, name }) => {
    setFormData({
      ...formData,
      [name]: data,
    });
  };

  // Debounced tìm kiếm
  useEffect(() => {
    setIndexPage(0);
    setLoading(true);
    const timeoutId = setTimeout(() => {
      Getlist(formData);
    }, 1500);
    return () => clearTimeout(timeoutId);
  }, [formData]);

  const handleRowClick = (record) => {
    setDetail(record);
    if (!checkAccountGC) {
      setPage(2);
    } else {
      setPage(3);
    }
  };

  //xet chiều cao window
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {
        openUpload &&
        <UploadFile open={true} handleCancel={handleCancel} />
      }
      <Animated style={{ width: '100%', zIndex: 1 }} animationIn="slideInUp" animationInDuration={700} isVisible={true}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontSize: 14,
            marginBottom: 20
          }}
        >
          <div style={{ fontWeight: 600, fontSize: 20 }}>
            Kho content
          </div>
          <div
            onClick={() => { setPage(2) }}
            style={{
              padding: "7px 11px",
              background: "#0B7CE4",
              width: "fit-content",
              color: "#fff",
              borderRadius: 5,
              display: checkAccountGC ? "none" : "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <img src={Itaogoi} style={{ width: 18, marginRight: 11 }} />
            Tạo bài viết
          </div>
        </div>
        <Row gutter={[10, 10]}>
          <Col xl={(checkAccountGC || selectedRowUIDs.length === 0) ? 5 : 7} md={12} xs={24}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Popconfirm
                className="customPopup"
                title={`Bạn muốn xoá ${selectedRowUIDs.length} bài viết?`}
                onConfirm={() => confirmDelete(selectedRowUIDs)}
                // onCancel={cancel}
                okText={"Xác nhận"}
                cancelText="Huỷ"
              >
                <div style={{ cursor: 'pointer', background: '#fff', display: selectedRowUIDs.length > 0 ? 'flex' : 'none', marginRight: 10, padding: '6px 20px', borderRadius: 5, border: '1px solid #D2D2D2', alignItems: 'center', justifyContent: 'center' }}>
                  <img src={Ixoa} width={17} height={17} style={{ marginRight: 5 }} /> Xoá
                </div>
              </Popconfirm>

              <div style={{ width: '100%' }}>
                <Form.Item
                  name="product_id"
                  rules={[
                    {
                      required: false,
                      message: "",
                    },
                  ]}
                  style={{ padding: 0, margin: 0 }}
                >
                  <Select
                    // showSearch
                    optionFilterProp="title"
                    allowClear={true}
                    className="custom-select"
                    placeholder={
                      <span style={{ color: "#343A40" }}>Chuyên mục</span>
                    }
                    onChange={(e) => {
                      onChangForm({ data: e, name: "product_id" });
                    }}
                  >
                    {product?.map((item) => (
                      <Option key={item?.id} value={item?.id}>
                        {item.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </div>
          </Col>
          {
            checkAccountGC &&
            <Col xl={5} md={12} xs={24}>
              <Form.Item
                name="provider_id"
                rules={[
                  {
                    required: false,
                    message: "",
                  },
                ]}
                style={{ padding: 0, margin: 0 }}
              >
                <Select
                  allowClear={true}
                  className="custom-select"
                  placeholder={
                    <span style={{ color: "#343A40" }}>Công ty bảo hiểm</span>
                  }
                  onChange={(e) => {
                    onChangForm({ data: e, name: "provider_id" });
                  }}
                >
                  {provider.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          }
          <Col xl={9} md={12} xs={24}>
            <Form.Item
              name="search"
              rules={[
                {
                  required: false,
                  message: "Vui lòng nhập đầy đủ thông tin.",
                },
              ]}
              style={{ padding: 0, margin: 0 }}
            >
              <Input
                onChange={(e) => {
                  onChangForm({ data: e.target.value, name: "search" });
                }}
                allowClear
                className="custom-input"
                prefix={
                  <img
                    src={Itimkiem}
                    alt="Global Care icon"
                    width={14}
                    height={14}
                  />
                }
                placeholder="Tìm kiếm..."
              />
            </Form.Item>
          </Col>
        </Row>
      </Animated>
      <Animated style={{ width: '100%', zIndex: 1 }} animationIn="slideInUp" animationInDuration={800} isVisible={true}>
        <div style={{ background: '#fff', width: '100%', marginTop: 15 }}>
          <div className="tabled2">
            <Table
              loading={loading}
              rowKey="uid"
              className="ant-border-spaces khuyenmai"
              rowSelection={!checkAccountGC ? {
                type: selectionType,
                ...rowSelection,
              } : ''}
              // size="small"
              pagination={false}
              columns={columns}
              dataSource={listContents}
              scroll={{
                y: windowHeight > 550 ? "calc(100vh - 366px)" : "51vh",
                x: 'auto'
              }}
              onRow={(record) => {
                return {
                  onClick: () => {
                    handleRowClick(record);
                  },
                };
              }}
            />
          </div>
          <div style={{ textAlign: "right", margin: "10px" }}>
            <Pagination
              className="paginateCustom2"
              total={pageS || 20}
              pageSize={20}
              showTotal={(total, range) => (
                <span
                  style={{ color: "#3961A0" }}
                >{`Hiển thị ${range[0]}-${range[1]} trong ${total}`}</span>
              )}
              defaultCurrent={1}
              defaultPageSize={0}
              // className="paginateCustom"
              onChange={(e) => {
                onChangForm({ data: e, name: "offset" });
              }}
              size="small"
            // scroll={{
            //   y: 240,
            // }}
            />
          </div>
        </div>
      </Animated>
    </>
  );
}

export default DanhSachContent;
