import {
  Row,
  Col,
  Table,
  Form,
  Select,
  DatePicker,
  Tooltip,
  Dropdown,
  Menu,
  TreeSelect,
  Spin,
  Skeleton
} from "antd";
import Service from "./../../extra/getAPI";
import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "./../../context/AuthProvider";
import { GlobalState } from "./index";
import Chart from 'react-apexcharts';
import Helper from "../../extra/helper";
import Iquestion from "./../../assets/images/salesManager/alert-circle.png";
import Itongxem from "./../../assets/images/salesManager/itongluotxem.png";
import Idon from "./../../assets/images/salesManager/Idon.png";
import Ituchoidon from "./../../assets/images/salesManager/Ituchoidon.png";
import Ityle from "./../../assets/images/salesManager/Ityle.png";
import Idoanhso from "./../../assets/images/salesManager/Idoanhso.png";
import Itang from "./../../assets/images/salesManager/itang.svg";
import Igiam from "./../../assets/images/salesManager/igiam.svg";
import ILoc from "./../../assets/images/salesManager/iloc.png";
import ILocCaoThap from "./../../assets/images/salesManager/iloccaothap.png";
import { Animated } from "react-animated-css";
import moment from "moment";

function Thongke() {
  const { checkAccountGC, setIndexPage, provider } =
    useContext(AuthContext);
  const {
    productsByProvider,
    iconChart
  } = useContext(GlobalState);
  const { TreeNode } = TreeSelect;
  const [form] = Form.useForm();
  const formRef = React.createRef();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({ date: new Date() });
  const [dataDashboard, setDataDashboard] = useState();
  // lại bảo hiểm của admin(thứ hạng loại user)
  const [productsTable, setProductsTable] = useState([]);
  // Sản phẩm
  const [productsProviderTable, setProductsProviderTable] = useState([]);
  const [sort, setSort] = useState({
    productsTable: 'ASC',
    productsProviderTable: 'ASC'
  });
  const { Option } = Select;
  const [series, setSeries] = useState([
    {
      name: "Tổng lượt xem",
      data: []
    },
    {
      name: "Đơn bảo hiểm",
      data: []
    }
    ,
    {
      name: "Doanh số",
      data: []
    }
    ,
    {
      name: "Từ chối đơn",
      data: []
    }
  ]);

  const [chartOptions, setChartOptions] = useState({
    chart: {
      height: 350,
      type: 'line',
      dropShadow: {
        enabled: true,
        color: '#000',
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2
      },
      zoom: {
        enabled: false
      },
      toolbar: {
        show: false
      }
    },
    colors: ['#3961A0', '#069D27', '#FFA21F', '#FF003D'],
    // hiện chi tiết giá trị là bao nhiêu
    dataLabels: {
      enabled: false,
      // formatter: (val) => {
      //   return Helper._formatMoney2(val); // Format number with commas
      // }
      formatter: (val) => Helper.formatNumber2(val)
    },
    stroke: {
      curve: 'smooth',
      width: 1
    },
    title: {
      text: "Tổng quan",
      align: 'left',
      style: {
        fontSize: '15px',
        fontWeight: 'bold'
      }
    },
    // grid: {
    //   borderColor: '#e7e7e7',
    //   row: {
    //     colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
    //     opacity: 0.5
    //   }
    // },
    markers: {
      size: 0
    },
    xaxis: {
      categories: [],
      // title: {
      //   text: 'Month'
      // }
    },
    yaxis: {
      // title: {
      //   text: 'Temperature'
      // },
      min: 0,
      labels: {
        formatter: (value) => {
          // Định dạng số ở trục Y với dấu phân cách hàng nghìn
          return Helper.formatNumber2(value);
          // Hoặc để làm tròn số
          // return Math.round(value);
        }
      },
      // max: 100000
    },
    legend: {
      position: 'top',
      horizontalAlign: 'center',
      floating: true,
      offsetY: -33,
      offsetX: 60,
      markers: {
        size: 0, // Ẩn icon mặc định
      },
      formatter: (seriesName) => {
        let iconHTML = '';
        // Tùy chỉnh icon cho từng series
        if (seriesName === 'Tổng lượt xem') {
          iconHTML = iconChart.luotxem
        } else if (seriesName === 'Đơn bảo hiểm') {
          iconHTML = iconChart.donbaohiem
        } else if (seriesName === 'Doanh số') {
          iconHTML = iconChart.doanhso
        } else if (seriesName === 'Từ chối đơn') {
          iconHTML = iconChart.tuchoidon
        }
        return `${iconHTML} ${seriesName}`;
      },
    },
    tooltip: {
      x: {
        show: false, // Disable the x-axis tooltip
      },
      y: {
        formatter: (val) => {
          return Helper._formatMoney2(val); // Format number with commas
        },
        // title: {
        //   formatter: (seriesName) => '', // Return an empty string to hide the title
        // }
      }
    }
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    // GetDataDashboard();
    setIndexPage(0);
  }, []);

  const productsTableColums = [
    {
      title: "TH",
      align: 'center',
      render: (item, record, index) => (
        <div style={{ minWidth: "10px" }}>
          {index + 1}
        </div>
      ),
    },
    {
      title: "Loại bảo hiểm",
      render: (_, data) => (
        <div style={{ textAlign: "left" }}>
          {data?.name}
        </div>
      ),
    },
    {
      title: "Tỷ lệ CĐ",
      align: 'center',
      render: (_, data) => (
        <div style={{ width: "60px", }}>
          {Helper._formatMoney2(data?.rate)}%
        </div>
      ),
    },
    {
      title: "Tổng đơn",
      align: 'right',
      render: (_, data) => (
        <div style={{ minWidth: 55 }}>
          {Helper._formatMoney2(data?.totalOrders)}
        </div>
      ),
    },
    {
      title: "Doanh số",
      align: 'right',
      fixed: "right",
      render: (_, data) => (
        <div style={{ minWidth: 50 }}>
          {Helper._formatMoney2(data?.sales)} đ
        </div>
      ),
    }
  ].filter((item) => !item.hidden);

  const productsProviderTableColums = [
    {
      title: "TH",
      align: 'center',
      render: (item, record, index) => (
        <div style={{ minWidth: "10px" }}>
          {index + 1}
        </div>
      ),
    },
    {
      title: "Thông tin sản phẩm",
      align: 'left',
      render: (_, data) => (
        <div>
          {data?.name}
        </div>
      ),
    },
    {
      title: "Tỷ lệ CĐ",
      align: 'center',
      render: (_, data) => (
        <div style={{ width: "60px" }}>
          {Helper._formatMoney2(data?.rate)}%
        </div>
      ),
    },
    {
      title: "Tổng đơn",
      align: 'right',
      render: (_, data) => (
        <div style={{ minWidth: 60 }}>
          {Helper._formatMoney2(data?.totalOrders)}
        </div>
      ),
    },
    {
      title: "Doanh số",
      align: 'right',
      fixed: "right",
      render: (_, data) => (
        <div style={{ minWidth: 50 }}>
          {Helper._formatMoney2(data?.sales)} đ
        </div>
      ),
    }
  ].filter((item) => !item.hidden);

  const onChangForm = ({ data, name }) => {
    setFormData({
      ...formData,
      [name]: data,
    });
  };

  const GetDataDashboard = (formData) => {
    const data = new URLSearchParams({
      month: moment(formData?.date).format("MM") || moment().format("MM"),
      year: moment(formData?.date).format("YYYY") || moment().format("YYYY"),
      providerID: formData?.providerID || '',
      productIDs: formData?.productIDs ? formData?.productIDs.join(',') : ''
    });
    Service.apicall(
      "GET",
      `/api/v1/products-hub/health/portal/dashboard?${data.toString()}`
    )
      .then((res) => {
        const data = res?.data?.result
        if (res.data.statusCode === 200) {
          setChartOptions({
            ...chartOptions, xaxis: {
              categories: Helper.createArrayNumber(data?.overviews?.length),
            }
          })
          setDataDashboard(data);
          setSeries([
            {
              name: "Tổng lượt xem",
              data: data.overviews.map(item => item.views)
            },
            {
              name: "Đơn bảo hiểm",
              data: data.overviews.map(item => item.orders)
            }
            ,
            {
              name: "Doanh số",
              data: data.overviews.map(item => item.sales)
            }
            ,
            {
              name: "Từ chối đơn",
              data: data.overviews.map(item => item.orderDeny)
            }
          ])
          setProductsTable(data.productsTable.sort((a, b) => b.sales - a.sales));
          setProductsProviderTable(data.productsProviderTable.sort((a, b) => b.sales - a.sales));
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  // Debounced tìm kiếm
  useEffect(() => {
    setLoading(true);
    const timeoutId = setTimeout(() => {
      GetDataDashboard(formData);
      setSort({
        productsTable: 'ASC',
        productsProviderTable: 'ASC'
      });
    }, 1500);
    return () => clearTimeout(timeoutId);
  }, [formData]);

  const menuProductsTable = (
    <Menu style={{ width: 'fit-content' }}
      onClick={(e) => {
        if (e.key === "ASC") {
          setSort({ ...sort, productsTable: 'ASC' });
          setProductsTable([...productsTable.sort((a, b) => b.sales - a.sales)]);
        } else {
          setSort({ ...sort, productsTable: 'DESC' });
          setProductsTable([...productsTable.sort((a, b) => a.sales - b.sales)]);
        }
      }}
    >
      <Menu.Item style={{ borderBottom: ' 1px solid #3961A0', color: '#3961A0' }} disabled>
        {sort.productsTable === "ASC" ? "Cao đến thấp" : 'Thấp đến cao'} <img src={ILocCaoThap} style={{ transform: sort.productsTable === "ASC" ? "rotate(180deg)" : "rotate(0deg)", marginLeft: 5 }} />
      </Menu.Item>
      <Menu.Item key="ASC">DS cao đến thấp</Menu.Item>
      <Menu.Item key="DESC">DS thấp đến cao</Menu.Item>
    </Menu>
  );

  const menuproductsProviderTable = (
    <Menu style={{ width: 'fit-content' }}
      onClick={(e) => {
        if (e.key === "ASC") {
          setSort({ ...sort, productsProviderTable: 'ASC' });
          setProductsProviderTable([...productsProviderTable.sort((a, b) => b.sales - a.sales)]);
        } else {
          setSort({ ...sort, productsProviderTable: 'DESC' });
          setProductsProviderTable([...productsProviderTable.sort((a, b) => a.sales - b.sales)]);
        }
      }}
    >
      <Menu.Item style={{ borderBottom: ' 1px solid #3961A0', color: '#3961A0' }} disabled>
        {sort.productsProviderTable === "ASC" ? "Cao đến thấp" : 'Thấp đến cao'} <img src={ILocCaoThap} style={{ transform: sort.productsProviderTable === "ASC" ? "rotate(180deg)" : "rotate(0deg)", marginLeft: 5 }} />
      </Menu.Item>
      <Menu.Item key="ASC">DS cao đến thấp</Menu.Item>
      <Menu.Item key="DESC">DS thấp đến cao</Menu.Item>
    </Menu>
  );

  const css = {
    input: {
      color: "#3A3A3A",
      zIndex: 1,
      fontSize: 12,
      boxShadow: "none",
      padding: "0px 10px",
      borderRadius: 4,
      width: "100%",
      border: "1px solid #d2d2d2",
    },
    titleInput: { color: "#646D69", marginBottom: 3 },
    FormItemsHolder: {
      width: "100%",
      borderRadius: 4,
      margin: "5px 0px",
      padding: 0,
    },
    button: {
      fontSize: 12,
      background: "#007185",
      color: "#fff",
      borderRadius: 4,
      height: 33,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
    },
    activeTabs: {
      padding: "15px 24px",
      borderBottom: "3px solid #3961A0",
      width: "fit-content",
      color: "#3961A0",
      fontWeight: 600,
      cursor: "pointer",
    },
    defaultTabs: {
      padding: "15px 24px",
      width: "fit-content",
      cursor: "pointer",
    },
  };

  return (
    <>
      <div >
        <Form
          layout={"vertical"}
          ref={formRef}
          scrollToFirstError
          form={form}
          // onFinish={onFinish}
          autoComplete="off"
        >
          <Row >
            <Animated style={{ width: '100%' }} animationIn="slideInDown" animationInDuration={700} isVisible={true}>
              <div style={{
                background: "#fff",
                padding: "10px 20px",
                width: '100%',
                display: 'flex',
                alignItems: 'center'
              }}>
                <div style={{ marginRight: 10, display: 'flex', alignItems: 'center' }}>
                  <div style={{ marginRight: 5 }}>Chọn khung thời gian:</div>
                  <DatePicker
                    allowClear={false}
                    placeholder="Chọn tháng"
                    // format={"MM.YYYY"}
                    format={(value) => `Theo tháng: ${Helper._formatDay3(value)}`}
                    style={{ borderRadius: 4, padding: "0px 6px", height: 37 }}
                    defaultValue={moment().subtract(0, "month")}
                    onChange={(e) => {
                      onChangForm({ data: e, name: "date" });
                    }}
                    picker="month"
                    monthCellRender={(date) => {
                      const month = moment(date).format("MM");
                      return <div className="pickerCustom"> Tháng {month}</div>;
                    }}
                  />
                </div>
                {
                  checkAccountGC &&
                  <div style={{ marginRight: 10 }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <div style={{ minWidth: 115 }}>Công ty bảo hiểm:{" "}</div>
                      <Form.Item
                        name="providerID"
                        rules={[
                          {
                            required: false,
                            message: "",
                          },
                        ]}
                        style={{ ...css.FormItemsHolder, marginTop: 6 }}
                      >
                        <Select
                          // showSearch
                          optionFilterProp="title"
                          style={{ width: 200 }}
                          allowClear={true}
                          className="custom-select"
                          placeholder={
                            <span style={{ color: "#343A40" }}>Công ty bảo hiểm</span>
                          }
                          onChange={(e) => {
                            onChangForm({ data: e, name: "providerID" });
                          }}
                        >
                          {provider.map((item) => (
                            <Option key={item.id} value={item.id}>
                              {item.title}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                }
                <div style={{ width: '33%' }}>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ minWidth: 68 }}>Sản phẩm:{" "}</div>
                    <Form.Item
                      className="TreeSelectCustom2"
                      style={{ ...css.FormItemsHolder, marginTop: 6 }}
                      name="productIDs"
                      rules={[
                        {
                          required: false,
                          message: "Vui lòng nhập thông tin.",
                        },
                      ]}
                    >
                      <TreeSelect
                        maxTagCount={"responsive"}
                        disabled={false}
                        // value={value}
                        dropdownStyle={{ overflow: 'auto', width: '100%' }}
                        placeholder="Chọn sản phẩm"
                        // open={false}
                        multiple
                        treeIcon={false}
                        treeCheckable={true}
                        showCheckedStrategy={TreeSelect.SHOW_ALL}
                        treeDefaultExpandAll
                        treeNodeFilterProp="title"
                        onChange={(e) => onChangForm({ data: e, name: "productIDs" })}
                      >
                        {
                          productsByProvider?.map((item) => (
                            <TreeNode key={item?.id} value={item?.id} title={item?.product_title} />
                          ))
                        }
                      </TreeSelect>
                    </Form.Item>
                  </div>
                </div>
              </div>
            </Animated>
            {/* <Animated style={{ width: '100%' }} animationIn="slideInUp" animationInDuration={700} isVisible={true}> */}
            <Col xl={24} xs={24} style={{ margin: '5px 0px' }}>
              <Spin spinning={false}>
                <div style={{ background: '#fff', padding: "10px 20px", display: 'flex' }}>
                  <Animated style={{ width: '20%' }} animationIn="flipInX" animationInDuration={1000} isVisible={true}>
                    <div style={{ padding: "10px 10px 10px 0px", borderRight: ' 1px solid #EEF3FA', width: '100%' }}>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div>
                          <div style={{ fontSize: 12, display: 'flex', alignItems: 'center' }}>
                            <div style={{ marginRight: 5 }}>Tổng lượt xem</div>
                            <Tooltip
                              overlayStyle={{ fontSize: 12 }}
                              mouseEnterDelay={0.5}
                              title={'Là tổng số lượt xem của các sản phẩm theo một khung thời gian nhất định.'}
                            >
                              <img src={Iquestion} width={16} style={{ cursor: 'pointer' }} />
                            </Tooltip>
                          </div>
                          <div style={{ fontSize: 21 }}>
                            {
                              loading ?
                                <Skeleton.Input active={true} size={21} style={{ width: 100, marginTop: 10 }} /> :
                                <b>{Helper._formatMoney(dataDashboard?.totalViews)}</b>
                            }
                          </div>
                        </div>
                        <div style={{ borderRadius: 10, boxShadow: '0px 1.68px 8.41px 0px #7C8DB51F', width: 37, height: 37, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <img src={Itongxem} width={16} />
                        </div>
                      </div>
                      <div style={{ marginTop: 10, display: 'flex', alignItems: 'center' }}>
                        {
                          loading ?
                            <Skeleton.Input active={true} size={15} style={{ width: 40, marginTop: 2, marginRight: 2 }} />
                            :
                            <>
                              <div>
                                <img src={+dataDashboard?.compareViewLastMonth < 0 ? Igiam : Itang} width={14} height={11} style={{ marginRight: 5 }} />
                              </div>
                              <div style={{ fontSize: 15, color: +dataDashboard?.compareViewLastMonth < 0 ? "red" : '#069D27', marginRight: 5 }}>{+dataDashboard?.compareViewLastMonth > 0 ? Helper._formatMoney2(+dataDashboard?.compareViewLastMonth) : (Helper._formatMoney2(+dataDashboard?.compareViewLastMonth * (-1)))}% </div>
                            </>
                        }
                        <div style={{ fontSize: 12, color: '#6685B6' }}>So với tháng trước</div>
                      </div>
                    </div>
                  </Animated>
                  <Animated style={{ width: '20%' }} animationIn="flipInX" animationInDuration={1000} isVisible={true}>
                    <div style={{ padding: "10px 10px 10px 10px", borderRight: ' 1px solid #EEF3FA', width: '100%' }}>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div>
                          <div style={{ fontSize: 12, display: 'flex', alignItems: 'center' }}>
                            <div style={{ marginRight: 5 }}>Đơn bảo hiểm</div>
                            <Tooltip
                              overlayStyle={{ fontSize: 12 }}
                              mouseEnterDelay={0.5}
                              title={'Là tổng tất cả các đơn bảo hiểm được thanh toán thành công của các sản phẩm theo một khung thời gian nhất định.'}
                            >
                              <img src={Iquestion} width={16} style={{ cursor: 'pointer' }} />
                            </Tooltip>
                          </div>
                          <div style={{ fontSize: 21 }}>
                            {
                              loading ?
                                <Skeleton.Input active={true} size={21} style={{ width: 100, marginTop: 10 }} /> :
                                <b>{Helper._formatMoney(dataDashboard?.totalOrders)}</b>
                            }
                          </div>
                        </div>
                        <div style={{ borderRadius: 10, boxShadow: '0px 1.68px 8.41px 0px #7C8DB51F', width: 37, height: 37, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <img src={Idon} width={16} />
                        </div>
                      </div>
                      <div style={{ marginTop: 10, display: 'flex', alignItems: 'center' }}>

                        {
                          loading ?
                            <Skeleton.Input active={true} size={15} style={{ width: 40, marginTop: 2, marginRight: 2 }} />
                            :
                            <>
                              <div><img src={+dataDashboard?.compareOrderLastMonth < 0 ? Igiam : Itang} width={14} height={11} style={{ marginRight: 5 }} /></div>
                              <div style={{ fontSize: 15, color: +dataDashboard?.compareOrderLastMonth < 0 ? "red" : '#069D27', marginRight: 5 }}>{+dataDashboard?.compareOrderLastMonth > 0 ? Helper._formatMoney2(+dataDashboard?.compareOrderLastMonth) : (Helper._formatMoney2(+dataDashboard?.compareOrderLastMonth * (-1)))}% </div>
                            </>
                        }
                        <div style={{ fontSize: 12, color: '#6685B6' }}>So với tháng trước</div>
                      </div>
                    </div>
                  </Animated>
                  <Animated style={{ width: '20%' }} animationIn="flipInX" animationInDuration={1000} isVisible={true}>
                    <div style={{ padding: "10px 10px 10px 10px", borderRight: ' 1px solid #EEF3FA', width: '100%' }}>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div>
                          <div style={{ fontSize: 12, display: 'flex', alignItems: 'center' }}>
                            <div style={{ marginRight: 5 }}>Tỉ lệ chuyển đổi</div>
                            <Tooltip
                              overlayStyle={{ fontSize: 12 }}
                              mouseEnterDelay={0.5}
                              title={'Là tỷ lệ phần trăm của các đơn hàng thanh toán thành công trên tổng tất cả các đơn được tạo của các sản phẩm theo một khung thời gian nhất định.'}
                            >
                              <img src={Iquestion} width={16} style={{ cursor: 'pointer' }} />
                            </Tooltip>
                          </div>
                          <div style={{ fontSize: 21 }}>
                            {
                              loading ?
                                <Skeleton.Input active={true} size={21} style={{ width: 100, marginTop: 10 }} /> :
                                <b>{Helper._formatMoney2(dataDashboard?.conversionRate)}%</b>
                            }
                          </div>
                        </div>
                        <div style={{ borderRadius: 10, boxShadow: '0px 1.68px 8.41px 0px #7C8DB51F', width: 37, height: 37, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <img src={Ityle} width={20} />
                        </div>
                      </div>
                      <div style={{ marginTop: 10, display: 'flex', alignItems: 'center' }}>

                        {
                          loading ?
                            <Skeleton.Input active={true} size={15} style={{ width: 40, marginTop: 2, marginRight: 2 }} />
                            :
                            <>
                              <div><img src={+dataDashboard?.conversionRateLastMonth < 0 ? Igiam : Itang} width={14} height={11} style={{ marginRight: 5 }} /></div>
                              <div style={{ fontSize: 15, color: +dataDashboard?.conversionRateLastMonth < 0 ? "red" : '#069D27', marginRight: 5 }}>{+dataDashboard?.conversionRateLastMonth > 0 ? Helper._formatMoney2(+dataDashboard?.conversionRateLastMonth) : (Helper._formatMoney2(+dataDashboard?.conversionRateLastMonth * (-1)))}% </div>
                            </>
                        }
                        <div style={{ fontSize: 12, color: '#6685B6' }}>So với tháng trước</div>
                      </div>
                    </div>
                  </Animated>
                  <Animated style={{ width: '20%' }} animationIn="flipInX" animationInDuration={1000} isVisible={true}>
                    <div style={{ padding: "10px 10px 10px 10px", borderRight: ' 1px solid #EEF3FA', width: '100%' }}>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div>
                          <div style={{ fontSize: 12, display: 'flex', alignItems: 'center' }}>
                            <div style={{ marginRight: 5 }}>Doanh số</div>
                            <Tooltip
                              overlayStyle={{ fontSize: 12 }}
                              mouseEnterDelay={0.5}
                              title={'Là tổng doanh thu của các đơn bảo hiểm đã hoàn tất.'}
                            >
                              <img src={Iquestion} width={16} style={{ cursor: 'pointer' }} />
                            </Tooltip>
                          </div>
                          <div style={{ fontSize: 21 }}>
                            {
                              loading ?
                                <Skeleton.Input active={true} size={21} style={{ width: 100, marginTop: 10 }} /> :
                                <b>{Helper._formatMoney2(dataDashboard?.sales)}đ</b>
                            }
                          </div>
                        </div>
                        <div style={{ borderRadius: 10, boxShadow: '0px 1.68px 8.41px 0px #7C8DB51F', width: 37, height: 37, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <img src={Idoanhso} width={16} />
                        </div>
                      </div>
                      <div style={{ marginTop: 10, display: 'flex', alignItems: 'center' }}>

                        {
                          loading ?
                            <Skeleton.Input active={true} size={15} style={{ width: 40, marginTop: 2, marginRight: 2 }} />
                            :
                            <>
                              <div><img src={+dataDashboard?.compareSalesLastMonth < 0 ? Igiam : Itang} width={14} height={11} style={{ marginRight: 5 }} /></div>
                              <div style={{ fontSize: 15, color: +dataDashboard?.compareSalesLastMonth < 0 ? "red" : '#069D27', marginRight: 5 }}>{+dataDashboard?.compareSalesLastMonth > 0 ? Helper._formatMoney2(+dataDashboard?.compareSalesLastMonth) : (Helper._formatMoney2(+dataDashboard?.compareSalesLastMonth * (-1)))}% </div>
                            </>
                        }
                        <div style={{ fontSize: 12, color: '#6685B6' }}>So với tháng trước</div>
                      </div>
                    </div>
                  </Animated>
                  <Animated style={{ width: '20%' }} animationIn="flipInX" animationInDuration={1000} isVisible={true}>
                    <div style={{ padding: "10px 10px 10px 10px", width: '100%' }}>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div>
                          <div style={{ fontSize: 12, display: 'flex', alignItems: 'center' }}>
                            <div style={{ marginRight: 5 }}>Từ chối đơn</div>
                            <Tooltip
                              overlayStyle={{ fontSize: 12 }}
                              mouseEnterDelay={0.5}
                              title={'Là tổng số đơn bị hủy sau khi nhà bảo hiểm thẩm định.'}
                            >
                              <img src={Iquestion} width={16} style={{ cursor: 'pointer' }} />
                            </Tooltip>
                          </div>
                          <div style={{ fontSize: 21 }}>
                            {
                              loading ?
                                <Skeleton.Input active={true} size={21} style={{ width: 100, marginTop: 10 }} /> :
                                <b>{Helper._formatMoney(dataDashboard?.totalOrdersDeny)}</b>
                            }
                          </div>
                        </div>
                        <div style={{ borderRadius: 10, boxShadow: '0px 1.68px 8.41px 0px #7C8DB51F', width: 37, height: 37, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <img src={Ituchoidon} width={16} />
                        </div>
                      </div>
                      <div style={{ marginTop: 10, display: 'flex', alignItems: 'center' }}>
                        {
                          loading ?
                            <Skeleton.Input active={true} size={15} style={{ width: 40, marginTop: 2, marginRight: 2 }} />
                            :
                            <>
                              <div><img src={+dataDashboard?.compareDenyLastMonth < 0 ? Igiam : Itang} width={14} height={11} style={{ marginRight: 5 }} /></div>
                              <div style={{ fontSize: 15, color: +dataDashboard?.compareDenyLastMonth < 0 ? "red" : '#069D27', marginRight: 5 }}>{+dataDashboard?.compareDenyLastMonth > 0 ? Helper._formatMoney2(+dataDashboard?.compareDenyLastMonth ): (Helper._formatMoney2(+dataDashboard?.compareDenyLastMonth * (-1)))}% </div>
                            </>
                        }
                        <div style={{ fontSize: 12, color: '#6685B6' }}>So với tháng trước</div>
                      </div>
                    </div>
                  </Animated>

                </div>
              </Spin>
            </Col>
            {/* </Animated> */}
            <Animated style={{ width: '100%' }} animationIn="fadeInUp" animationInDuration={700} isVisible={true}>
              <Spin spinning={loading}>
                <Col xs={24} style={{ position: 'relative' }}>
                  <div style={{ width: '100%', background: "#fff", padding: '10px 13px' }}>
                    <Chart
                      options={chartOptions}
                      series={series}
                      type="line"
                      height={400}
                    />
                  </div>
                  <div style={{ fontSize: 12, color: '#6685B6', position: 'absolute', top: 15, left: 100 }}>Trong tháng {Helper._formatDay4(formData?.date)}</div>
                </Col>
              </Spin>
            </Animated>
          </Row>
        </Form>
      </div>
      <Animated style={{ width: '100%' }} animationIn="slideInUp" animationInDuration={700} isVisible={true}>
        <Spin spinning={loading}>

          <div style={{ marginTop: 10, display: 'flex' }}>
            <div style={{ width: '50%', padding: '10px 10px', background: "#fff", marginRight: 5 }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ padding: '0px 0px 10px 0px', fontSize: 15 }}><b>Loại bảo hiểm</b> <span style={{ fontSize: 12, color: '#6685B6', }}>Trong tháng {Helper._formatDay4(formData?.date)}</span>
                </div>
                <div>
                  <Dropdown placement="bottomRight" trigger={["click"]} overlay={menuProductsTable}>
                    <div style={{ cursor: 'pointer' }}>
                      <img src={ILoc} width={15} style={{ marginRight: 5 }} />Lọc
                    </div>
                  </Dropdown>
                </div>
              </div>
              <Table
                loading={false}
                columns={productsTableColums}
                dataSource={productsTable}
                pagination={false}
                className="ant-border-spaces tableDashboard"
                bordered={false}
                scroll={{
                  x: 'auto',
                  y: 400
                }}
                showHeader={true}
              />
            </div>
            <div style={{ width: '50%', padding: '10px 10px', background: "#fff", marginLeft: 5 }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ padding: '0px 0px 10px 0px', fontSize: 15 }}>
                  <b>Sản phẩm</b> <span style={{ fontSize: 12, color: '#6685B6', }}>Trong tháng {Helper._formatDay4(formData?.date)}</span>
                </div>
                <div>
                  <Dropdown placement="bottomRight" trigger={["click"]} overlay={menuproductsProviderTable}>
                    <div style={{ cursor: 'pointer' }}>
                      <img src={ILoc} width={15} style={{ marginRight: 5 }} />Lọc
                    </div>
                  </Dropdown>
                </div>
              </div>
              <Table
                loading={false}
                columns={productsProviderTableColums}
                dataSource={productsProviderTable}
                pagination={false}
                className="ant-border-spaces tableDashboard"
                bordered={false}
                scroll={{
                  x: 'auto',
                  y: 400
                }}
                showHeader={true}
              />
            </div>
          </div>
        </Spin>
      </Animated>
    </>
  );
}

export default Thongke;
